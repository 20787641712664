import React from 'react'
import { FaUserAlt } from 'react-icons/fa'
import { componentsInterface } from '../interface'
import config from '../config/conf'
import Loading from './loading'
import { motion } from 'framer-motion'

const Avatar: React.FC<componentsInterface.Avatar> = (props: componentsInterface.Avatar) => {
    const { src, alt, width, height, action, customClass } = props
    return (
        <div
            className={!customClass ? 'header__avatar' : customClass}
            onClick={() => {
                action && action()
            }}
        >
            {props.isloading && <Loading />}
            {src ? (
                <motion.img
                    src={config.api.urlBase + src}
                    alt={alt ? alt : ''}
                    width={width ? width : 'auto'}
                    height={height ? height : 'auto'}
                    whileHover={{
                        scale: 1.1,
                        transition: { duration: 0.3 },
                    }}
                />
            ) : (
                <FaUserAlt className='header_btn-user' />
            )}
        </div>
    )
}

export default Avatar
