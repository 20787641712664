import { AiOutlineLoading3Quarters } from 'react-icons/ai'

const Loading = () => {
    return (
        <div className='loading'>
            <div className='loading__spinner'>
                <div className='loading__spinner-inner'>
                    <AiOutlineLoading3Quarters className='loading__circle' />
                </div>
            </div>
        </div>
    )
}

export default Loading
