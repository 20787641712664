import { motion } from 'framer-motion'
import React from 'react'
import { FaWindowClose } from 'react-icons/fa'
import { componentsInterface } from '../interface'

const Modal: React.FC<componentsInterface.Modal> = (props: componentsInterface.Modal) => {
    return (
        <motion.div
            key={'modal'}
            exit={{ opacity: 0, transition: { duration: 0.3, type: 'tween' } }}
        >
            {props.isOpen && (
                <div className='modal'>
                    <div className='modal__content'>
                        {props.children}

                        <button className='modal__close' onClick={() => props.onClose()}>
                            <FaWindowClose />
                        </button>
                    </div>
                </div>
            )}
        </motion.div>
    )
}

export default Modal
