const Legal = () => {
    document.title = 'Mentions légales'

    const nameSite = 'Devis Power'
    const nameCompany = 'Jhon.Dev'
    const tvaNumber = ' FR37904797255'
    const addressCompany = '6 rue Renée Hareau 97480 Saint Joseph Réunion'
    const editeurName = 'Gauvin Jonathan Expédit'
    const editeurPhoneNember = '06.92.41.75.74'
    const siretCompany = '904 797 255 00018'
    return (
        <div className='legal '>
            <div className='legal__hero'>
                <h1> Présentation du generateur de devis et du site internet</h1>
            </div>
            <div className='legal__content container'>
                <p>
                    Le site {nameSite} a pour objet de fournir des informations sur le produit
                    proposé par la société {nameCompany}, qui propose le développement et la
                    distribution de solutions digitales innovantes à destination des professionnels.
                </p>

                <p>
                    Le présent site internet est la propriété de la société {nameCompany}, n° de tva
                    {tvaNumber} inscrite {nameCompany}.
                </p>

                <h3>{nameCompany}</h3>
                <p>
                    * ne garantit en aucune façon l’exactitude, la précision ou l’exhaustivité des
                    informations mises à disposition sur ce site, y compris l’ensemble des
                    hyperliens ou toute autre liaison informatique utilisée, directement ou
                    indirectement, à partir de ce site. Les photos présentées sur le site {nameSite}
                    sont non contractuelles. La société {nameCompany} s’efforce d’assurer la
                    fiabilité de l’ensemble des informations diffusées sur ce site, dont elle se
                    réserve le droit de modifier ou de corriger, à tout moment et sans préavis, le
                    contenu.
                </p>

                <p>
                    <h3> Décline toute responsabilité :</h3>
                    pour toute imprécision, inexactitude ou omission portant sur des informations
                    disponibles sur le site <br />
                    pour tout dommage susceptible de résulter du crédit accordé à ces informations,
                    <br />
                    de leur utilisation ou de l’utilisation d’un produit ou service auquel elles
                    font référence <br />
                    et plus généralement pour tous dommages, directs ou indirects, quelles qu’en
                    soient les causes, origines, natures et conséquences, provoquées à raison de
                    l’accès de quiconque à ce site, de son usage ou de l’usage d’autres sites qui
                    lui sont liés, de même que de l’impossibilité d’accéder à ce site. <br />
                    Les documents contenus dans ce site et chacun des éléments créés pour ce site
                    sont soumis aux dispositions régissant le droit de la propriété intellectuelle.
                    Aucune licence, ni aucun autre droit que celui de consulter ce site, n’est
                    conféré à quiconque au regard de ces mêmes dispositions. La reproduction de tous
                    documents publiés sur ce site est uniquement autorisée aux fins exclusives
                    d’information pour un usage personnel et privé, toute reproduction et toute
                    utilisation de copies réalisées à d’autres fins étant expressément interdite.
                </p>

                <p>
                    Toute personne citée sur une page de ce site peut exercer ses droits d’accès, de
                    modification, de rectification ou de suppression des informations le concernant
                    :
                </p>

                <h3>Politique de contenu du site</h3>

                <p>
                    La structure générale, les textes, les animations au sens large du site Internet
                    sont des propriétés de la société {nameCompany} Toute tentative de
                    représentation totale ou partielle du site et de son contenu sans autorisation
                    préalable de la société {nameCompany}, est strictement interdite sous peine
                    d’entrer en infraction avec le Code de la Propriété intellectuelle qui
                    sanctionne les délits de contrefaçon. L’utilisateur s’engage à respecter les
                    règles de propriété intellectuelle des divers contenus proposés sur notre site
                    c’est à dire :
                </p>

                <p>
                    Ne pas reproduire, modifier, altérer ou rediffuser, sans notre autorisation,
                    quelque article, titre, applications, logiciels, logo, marque, information pour
                    un usage autre que strictement privé, ce qui exclut toute reproduction à des
                    fins professionnelles ou de diffusion en nombre. <br />
                    Ne pas recopier tout ou partie du site sur un autre site ou un réseau interne de
                    l’entreprise. <br />
                    La violation de ces dispositions impératives soumet le contrevenant, et toutes
                    personnes responsables, aux peines pénales et civiles prévues par la loi.
                </p>

                <p>
                    Sécurité et confidentialité des informations personnelles <br />
                    {nameCompany} s’engage à assurer la sécurité des informations que les
                    utilisateurs lui confient en ligne. {nameCompany} considère la protection des
                    données personnelles comme une priorité et garantit la sécurité des informations
                    transmises dans une relation de confiance. {nameCompany} s’engage à traiter les
                    données personnelles de manière confidentielle et à ne diffuser aucune donnée
                    susceptible de vous identifier. <br />
                    Pour plus d’informations concernant les données personnelles, veuillez consulter
                    la rubrique « Vos données et vos droits » .
                </p>
                <h3> Informations légales</h3>
                <p>
                    {nameCompany} <br />
                    {addressCompany} <br />
                    N° SIREN :{siretCompany} <br />
                    Directeur et éditeur de la publication : <br />
                    {editeurName + ' ' + editeurPhoneNember} <br />
                    Hébergeur du site : <br />
                    OVH SAS <br />
                    2 rue Kellermann – 59100 Roubaix – +33 (0)8 203 203 63 <br />
                    http://www.ovh.fr/
                </p>
            </div>
        </div>
    )
}

export default Legal
