import { useNavigate } from 'react-router'
import { useEffect, useState } from 'react'
import { Loading } from '../components'
const Page404 = () => {
    document.title = '404 Page non trouvée'

    const navigate = useNavigate()
    const [number, setNumber] = useState(6)

    useEffect(() => {
        const timer = setInterval(() => {
            setNumber((prevNumber) => prevNumber - 1)
        }, 1000)
        return () => clearInterval(timer)
    }, [])

    if (number <= 0) {
        navigate('/')
    }

    return (
        <div className='page404'>
            <div className='page404__content'>
                <Loading />
                <h1 className='Page404__content-title'>404</h1>
                <p>La page que vous cherchez n&apos;existe pas où n&apos;existe plus</p>
                <p>vous allez être redirigé vers l&apos;accueil dans {number} secondes </p>
            </div>
        </div>
    )
}

export default Page404
