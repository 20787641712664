import { FaFileInvoice, FaPrint } from 'react-icons/fa'
import { module_custon } from '../interface'
import Logic from '../util/logic'
import Accordion from './accordion'
import Switch from './switch'

interface SettingsProps {
    curentDevis: module_custon.customDevi
    setCurentdevis: (devis: module_custon.customDevi) => void
    customTable: {
        colorHeader: string
        colortextHeader: string
        colorLigne: string
        colortextLigne: string
        colorLigne2: string
        colortextLigne2: string
        devise: string
        discountPerLine: boolean
        autoentrepreneur: boolean
        tva: boolean
    }
    setCustomTable: (customTable: {
        colorHeader: string
        colortextHeader: string
        colorLigne: string
        colortextLigne: string
        colorLigne2: string
        colortextLigne2: string
        devise: string
        discountPerLine: boolean
        autoentrepreneur: boolean
        tva: boolean
    }) => void
    setTotal: (value: {
        discount: number
        totalHT: number
        totalTVA: number
        totalTTC: number
    }) => void
    setTypePage: (value: string) => void
    typePage: string
}

const SettingsDevis = (props: SettingsProps) => {
    const {
        setCustomTable,
        customTable,
        curentDevis,
        setCurentdevis,
        setTypePage,
        setTotal,
        typePage,
    } = props
    return (
        <section className='devis__setings'>
            <div className='devis__setings-header'>Réglages</div>
            <div className='devis__setings-body'>
                <div>
                    <Switch
                        label='Activer la TVA'
                        on={customTable.tva}
                        onClick={(value: boolean) => {
                            setCustomTable({
                                ...customTable,
                                tva: value,
                                autoentrepreneur: !value,
                            })
                        }}
                    />
                </div>
                {!customTable.tva && (
                    <div>
                        <Switch
                            label='Êtes-vous auto-entrepreneur'
                            on={customTable.autoentrepreneur}
                            onClick={(value: boolean) => {
                                setCustomTable({
                                    ...customTable,
                                    autoentrepreneur: value,
                                })
                            }}
                        />
                    </div>
                )}
                {customTable.tva && (
                    <div className='devis__input'>
                        <p>Taux TVA</p>
                        <span>
                            <input
                                type='number'
                                step='0.01'
                                value={curentDevis.data.doc.doc_data.provider.tva}
                                onChange={(e) => {
                                    const d = { ...curentDevis }
                                    d.data.doc.doc_data.provider.tva = parseFloat(e.target.value)
                                    Logic.calculResult(
                                        d.data.doc.doc_data.lignes,
                                        d.data.doc.doc_data.provider.tva,
                                        setTotal,
                                    )
                                    setCurentdevis(d)
                                }}
                            />
                        </span>
                    </div>
                )}
                <div className='devis__input'>
                    <Switch
                        label='Activer remise par ligne'
                        on={true}
                        onClick={(value: boolean) => {
                            if (!value) {
                                const devis = { ...curentDevis }
                                devis.data.doc.doc_data.lignes.forEach((l) => {
                                    l.remise = '0'
                                })
                            }
                            props.setTotal(
                                Logic.calculResult(
                                    props.curentDevis.data.doc.doc_data.lignes,
                                    props.customTable.tva
                                        ? props.curentDevis.data.doc.doc_data.provider.tva
                                        : 0,
                                    setTotal,
                                ),
                            )

                            setCustomTable({
                                ...customTable,
                                discountPerLine: value,
                            })
                        }}
                    />
                </div>

                <Accordion label='Apparence' classNames='devis-accordion'>
                    <div className='devis__setings-header devis__setings-headerSmall '>
                        Entete du tableau
                    </div>
                    <div className='devis__input'>
                        <span>Arrière-plan</span>
                        <span>
                            <input
                                type='color'
                                value={customTable.colorHeader}
                                onChange={(value) =>
                                    setCustomTable({
                                        ...customTable,
                                        colorHeader: value.target.value,
                                    })
                                }
                            />
                        </span>
                    </div>
                    <div className='devis__input'>
                        <span>Texte </span>
                        <span>
                            <input
                                type='color'
                                value={customTable.colortextHeader}
                                onChange={(value) =>
                                    setCustomTable({
                                        ...customTable,
                                        colortextHeader: value.target.value,
                                    })
                                }
                            />
                        </span>
                    </div>
                    <div className='devis__setings-header devis__setings-headerSmall '>
                        Ligne paire
                    </div>
                    <div className='devis__input'>
                        <span>Arrière-plan</span>
                        <span>
                            <input
                                value={customTable.colorLigne}
                                type='color'
                                onChange={(value) =>
                                    setCustomTable({
                                        ...customTable,
                                        colorLigne: value.target.value,
                                    })
                                }
                            />
                        </span>
                    </div>
                    <div className='devis__input'>
                        <span>Texte</span>
                        <span>
                            <input
                                value={customTable.colortextLigne}
                                type='color'
                                onChange={(value) =>
                                    setCustomTable({
                                        ...customTable,
                                        colortextLigne: value.target.value,
                                    })
                                }
                            />
                        </span>
                    </div>
                    <div className='devis__setings-header devis__setings-headerSmall '>
                        Ligne impaire
                    </div>
                    <div className='devis__input'>
                        <span>Arrière-plan</span>
                        <span>
                            <input
                                value={customTable.colorLigne2}
                                type='color'
                                onChange={(value) =>
                                    setCustomTable({
                                        ...customTable,
                                        colorLigne2: value.target.value,
                                    })
                                }
                            />
                        </span>
                    </div>
                    <div className='devis__input'>
                        <span>Texte</span>
                        <span>
                            <input
                                value={customTable.colortextLigne2}
                                type='color'
                                onChange={(value) =>
                                    setCustomTable({
                                        ...customTable,
                                        colortextLigne2: value.target.value,
                                    })
                                }
                            />
                        </span>
                    </div>
                </Accordion>

                <div className='devis__setings-header devis__setings-headerSmall '>Devise</div>
                <div className='devis__input'>
                    <span>Devise</span>
                    <span>
                        <select
                            name='devise'
                            id=''
                            onChange={(value) => {
                                setCustomTable({
                                    ...customTable,
                                    devise: value.target.value,
                                })
                            }}
                        >
                            <option defaultChecked={true} value='€'>
                                Euro
                            </option>
                            <option value='$'>Dollar</option>
                            <option value='£'>Livre</option>
                        </select>
                    </span>
                </div>

                <div className='devis__setings-footer'>
                    {/* <button
                        onClick={() => {
                            console.log('customTable')
                        }}
                    >
                        <span>Télécharger devis</span>
                        <FaDownload />
                    </button> */}
                    <button onClick={() => print()}>
                        <span> Imprimer devis</span>
                        <FaPrint />
                    </button>
                    <button
                        onClick={() => {
                            if (typePage === 'devis') {
                                setTypePage('facture')
                            } else {
                                setTypePage('devis')
                            }
                        }}
                    >
                        <span>{typePage === 'devis' ? 'Voir la facture' : 'Voir le devis'}</span>
                        <FaFileInvoice />
                    </button>
                </div>
            </div>
        </section>
    )
}

export default SettingsDevis
