import { module_custon } from '../interface'
import User from '../util/user'

interface PanelProps {
    onValidate: (value: boolean) => void
    user: User | null | undefined
    devis: module_custon.customDevi
    onCancel: () => void

    children: React.ReactNode
    showAlert: (message: string, timer: number, type: string) => void
}
const PanelSaveDevis = (props: PanelProps) => {
    const { onValidate, onCancel } = props
    return (
        <div className='panel-save-devis'>
            <div className='panel-save-devis__content'>
                {props.children}
                <div className='content-btn'>
                    <button
                        onClick={() => {
                            props.user
                                ?.setDefaultProvider({
                                    address: props.devis.data.provider.address,
                                    city: props.devis.data.provider.city,
                                    mobile: props.devis.data.provider.mobile,
                                    fax: props.devis.data.provider.fax,
                                    tva: props.devis.data.provider.tva,
                                    siret: props.devis.data.provider.siret,
                                    email_pro: props.devis.data.provider.email_pro,
                                    company_name: props.devis.data.provider.company_name,
                                    phone: props.devis.data.provider.phone,
                                    zip_code: props.devis.data.provider.zip_code,
                                })
                                .then(() => {
                                    props.showAlert(
                                        'Vos informations ont bien étais mise à jour',
                                        3000,
                                        'success',
                                    )
                                    onValidate(true)
                                })
                                .catch((err: Error) => {
                                    props.showAlert(
                                        'Erreur lors de la sauvegarde de vos information',
                                        3000,
                                        'error',
                                    )
                                    throw new Error(err.message)
                                })
                        }}
                    >
                        Enregistrer
                    </button>
                    <button onClick={() => onCancel()}>Non merci</button>
                </div>
            </div>
        </div>
    )
}

export default PanelSaveDevis
