import React, { useState } from 'react'
import { useForm, ValidationError } from '@formspree/react'
import { Button } from '../components'
import Logic from '../util/logic'

function ContactForm() {
    const [state, handleSubmit] = useForm('xdobrdgy')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [formfields, setFormfields] = React.useState({
        'full-name': '',
        email: '',
        message: '',
    })
    if (state.succeeded) {
        return (
            <>
                <div className='contact-form__response'>
                    <p>Merci de nous avoir contactés! </p>
                    <p>Nous vous répondrons dans les plus brefs délais.</p>
                </div>
            </>
        )
    }

    const clearForm = () => {
        setFormfields({
            'full-name': '',
            email: '',
            message: '',
        })
    }
    const handleChange = (event) => {
        setFormfields({
            ...formfields,
            [event.target.name]: Logic.customsSanitizeHtml(event.target.value),
        })

        if (formfields.email !== '' && formfields['full-name'] != '' && formfields.message != '') {
            setIsSubmitting(true)
        }
    }

    return (
        <form className='contact-form' onSubmit={handleSubmit}>
            <div>
                <label htmlFor='full-name'>Votre nom et prénom</label>
                <input
                    id='full-name'
                    type='text'
                    name='full-name'
                    value={formfields['full-name']}
                    onChange={handleChange}
                    placeholder='Entrer votre nom'
                />
            </div>
            <div>
                <label htmlFor='email'>Votre adresse e-mail</label>
                <input
                    id='email'
                    type='email'
                    name='email'
                    value={formfields.email}
                    onChange={handleChange}
                    placeholder='Entrer votre email'
                />

                <ValidationError prefix='Email' field='email' errors={state.errors} />
            </div>
            <div>
                <label htmlFor='message'>Votre Message</label>
                <textarea
                    id='message'
                    name='message'
                    value={formfields.message}
                    onChange={handleChange}
                    placeholder='Entrer votre message'
                />
                <ValidationError prefix='Message' field='message' errors={state.errors} />
            </div>
            <div className='contact__form-btn'>
                <Button
                    color='secondary'
                    callBack={() => {
                        clearForm()
                    }}
                    size='btn--small'
                >
                    Vider le formulaire
                </Button>
                {isSubmitting && (
                    <input type='submit' disabled={state.submitting} value='Envoyer' />
                )}
            </div>
        </form>
    )
}

export default ContactForm
