/* Lib abd class */
import { FaCheck, FaArrowRight } from 'react-icons/fa'
import { homeInterface } from '../interface'
/* Components */
import { Button } from '../components'
import { Player } from '@lottiefiles/react-lottie-player'
/* img */
import mockup from '../assets/img/mockup_hero.webp'
import reglage from '../assets/svg/reglage1.svg'
import table from '../assets/svg/Table.svg'
import table_m from '../assets/svg/Table-m.svg'

import listDevis from '../assets/svg/Listedevis.svg'
import listDevis_m from '../assets/svg/Listedevis-m.svg'

import color_selector from '../assets/svg/SelectorColorTableBlock.svg'
import quotation from '../assets/svg/devis.svg'
import invoice from '../assets/svg/facture.svg'
import { useNavigate } from 'react-router'
import { motion } from 'framer-motion'

const Home: React.FC = () => {
    document.title = 'Accueil'

    const navigate = useNavigate()
    return (
        <>
            <section className='home'>
                <div className=' home__content padding-content container'>
                    <div className='home__left'>
                        <h1>Générateur de devis 100% GRATUIT</h1>

                        <ul className='home__content-chekList'>
                            <CheckList text='Création de devis ilimitée.' />
                            <CheckList text='Enregistrement des devis en ligne.' />
                            <CheckList text='Téléchargement et impression rapide.' />
                            <CheckList text='Transformation en facture.' />
                        </ul>

                        <Button
                            color={'primary'}
                            callBack={() => {
                                navigate('/devis', { replace: false })
                            }}
                        >
                            Générer un devis
                        </Button>
                    </div>

                    <div className='home__mockup'>
                        <img
                            src={mockup}
                            loading='lazy'
                            width={562}
                            height={333}
                            alt='Illustration du service dans un mokup de PC portable
'
                        />
                    </div>
                </div>
            </section>

            <section className=' services-content padding-content '>
                <h2>Profitez de nombreuses options</h2>
                <div className='services-content__settings '>
                    <HomeInfo
                        label={'Devis personnalisable'}
                        img={[{ src: reglage, width: 800, height: 500, alt: '' }]}
                        desc={
                            'Personnalisez votre devis grâce au réglage pour changer les couleurs des cases ou bien autres encore...'
                        }
                        TypeOfTitle={'h3'}
                    />
                </div>

                <div className='services-content__table services-content__pc'>
                    <HomeInfo
                        label={'Tableau interactif'}
                        img={[{ src: table, width: 800, height: 170, alt: '' }]}
                        desc={
                            "Tableau 100% interactif changer l'ordre de vos lignes ou supprimer en une de manière rapide et intuitive"
                        }
                        TypeOfTitle={'h3'}
                    />
                </div>
                <div className='services-content__table services-content__mobile'>
                    <HomeInfo
                        label={'Tableau Interactive'}
                        img={[{ src: table_m, width: 300, height: 300, alt: '' }]}
                        desc={
                            "Tableau 100% Interactive changer l'ordre de vos lignes ou supprimer en une de manière rapide et intuitive"
                        }
                        TypeOfTitle={'h3'}
                    />
                </div>

                <div className='services-content__btn-save services-content__pc'>
                    <HomeInfo
                        label={'Sauvegarde'}
                        img={[{ src: listDevis, width: 800, height: 180, alt: '' }]}
                        desc={'Sauvegarder vos devis en ligne '}
                        TypeOfTitle={'h3'}
                    />
                </div>
                <div className='services-content__btn-save services-content__mobile'>
                    <HomeInfo
                        label={'Sauvgarder vos devis en ligne'}
                        img={[{ src: listDevis_m, width: 300, height: 300, alt: '' }]}
                        desc={'Sauvegarder vos devis en ligne de manière illimitée'}
                        TypeOfTitle={'h3'}
                    />
                </div>
                <div className='services-content__style'>
                    <HomeInfo
                        label={'Devis personnalisable'}
                        img={[{ src: color_selector, width: 360, height: 500, alt: '' }]}
                        desc={
                            'Personnalisez votre devis grâce aux réglages pour changer les couleurs du tableau, etc...'
                        }
                        TypeOfTitle={'h3'}
                    />
                </div>
            </section>

            {/* quotation */}
            <section className='quotation-mockup padding-content'>
                <h1>Votre devis transformé en facture en un clic</h1>

                <div className=' quotation-mockup-img '>
                    <img
                        src={quotation}
                        alt='devis mockup'
                        loading='lazy'
                        width={560}
                        height={792}
                    />

                    <FaArrowRight aria-hidden={true} />

                    <img
                        src={invoice}
                        alt='facture mockup'
                        width={560}
                        height={792}
                        loading='lazy'
                    />
                </div>
            </section>
        </>
    )
}
/* Component */
const CheckList: React.FC<homeInterface.CheckListProps> = (props: homeInterface.CheckListProps) => {
    return (
        <li className='home__checkList'>
            <FaCheck className='check-icon' aria-hidden={true} />

            <p>{props.text} </p>
        </li>
    )
}
const HomeInfo: React.FC<homeInterface.HomeInfoProps> = (props: homeInterface.HomeInfoProps) => {
    let title: React.ReactNode

    switch (props.TypeOfTitle) {
        case 'h3':
            title = <h3 className='services__title'>{props.label}</h3>
            break
        case 'h4':
            title = <h4 className='services__title'>{props.label}</h4>
            break
        case 'h5':
            title = <h5 className='services__title'>{props.label}</h5>
            break
        case 'h6':
            title = <h6 className='services__title'>{props.label}</h6>
            break
    }

    return (
        <>
            <div className='services'>
                <div className='services__text'>
                    {title}

                    <p>{props.desc}</p>
                </div>

                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    viewport={{ once: true }}
                    className='services__img'
                >
                    {props.img.map((_img) => {
                        if (!_img.type) {
                            _img.type = 'img'
                        }
                        if (_img.type === 'img') {
                            return (
                                <img
                                    src={_img.src}
                                    alt={_img.alt}
                                    width={_img.width}
                                    height={_img.height}
                                    loading='lazy'
                                    key={_img.src}
                                    style={props.style}
                                />
                            )
                        } else {
                            return (
                                <Player
                                    autoplay={true}
                                    loop={false}
                                    keepLastFrame
                                    src={_img.src}
                                    key={_img.src}
                                    style={props.style}
                                ></Player>
                            )
                        }
                    })}
                </motion.div>
            </div>
        </>
    )
}

export default Home
