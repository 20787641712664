import { Accordion } from '../components'

const Faq = () => {
    document.title = 'FAQ'
    return (
        <>
            <div className='profil__hero padding-content'>
                <h1>FAQ</h1>
                <p>Ici vous trouverez des réponses a vos questions</p>
            </div>
            <main className='container faq padding-content'>
                <Accordion
                    classNames='accordion'
                    label='1. Combien coûte le service de devis de facturation ?'
                >
                    <p>
                        Le service est complètement gratuit et ne demande pas d&apos;abonnement ou
                        d&apos;achat de licence.
                    </p>
                </Accordion>

                <Accordion
                    classNames='accordion'
                    label="2. Quels sont les formats d'exportation supportés par le site ?"
                >
                    <p>
                        Pour le moment le site vous permet d&apos;exporter vos devis au format PDF
                        mais nous envisageons dans le futur de supporter d&apos;autres formats.
                    </p>
                </Accordion>
                <Accordion
                    classNames='accordion'
                    label='3. Que faites-vous de mes données personnelles adresses e-mails ou d’autres types de données ?'
                >
                    <p>
                        Nous ne transmettrons pas vos données à d&apos;autres acteurs ou partenaires
                        du site vos données sont sauvegardées sur un serveur français et ne seront
                        jamais utilisées à des fins commerciales.
                    </p>
                </Accordion>
                <Accordion
                    classNames='accordion'
                    label='4. Est-ce que je peux supprimer mon compte à tous moments ?'
                >
                    <p>
                        Oui vous pouvez supprimer votre compte à tous moments et vos données seront
                        supprimées de nos serveurs immédiatement.
                    </p>
                </Accordion>
                <Accordion
                    classNames='accordion'
                    label='5. Comment votre site finance ses serveurs ?'
                >
                    <p>
                        Nous disposons d&apos;un système de publicité non intrusive ce qui nous
                        permet de faire vivre notre service.
                    </p>
                </Accordion>
            </main>
        </>
    )
}

export default Faq
