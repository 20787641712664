/* eslint-disable prettier/prettier */
import { useState } from 'react'
import { Logout, Avatar } from './'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useNavigate } from 'react-router'
import logo from '../assets/img/logo/Logo.svg'
import User from '../util/user'
import { Link } from 'react-router-dom'

interface headerMProprs {
    user: User | null | undefined
    showAlert: (message: string, timer: number, type: string) => void
    setShowListDevis: (value: boolean) => void
    UpdateUser: (value: User | null) => void
    handleRegister: () => void
    handleLogin: () => void
}

const HeaderMobile = (props: headerMProprs) => {
    const navigate = useNavigate() // React router navigate
    const { user, setShowListDevis, handleRegister, handleLogin } = props
    const [isOpen, setIsOpen] = useState(false)

    const handleIsOpen = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <header className='header__mobile'>
                <div className='header__mobile-burger'>
                    <GiHamburgerMenu onClick={() => handleIsOpen()} />
                </div>

                {isOpen && (
                    <div className='header__open'>
                        <img
                            className='header__open-logo'
                            src={logo}
                            alt='logo'
                            width={52}
                            height={63}
                            onClick={() => {
                                handleIsOpen()
                                navigate('/')
                            }}
                        />

                        <nav className='header__nav'>
                            <ul className='header__nav-block'>
                                <li>
                                    <Link onClick={() => handleIsOpen()} to='/'>
                                        Accueil
                                    </Link>
                                </li>
                                {user && (
                                    <li>
                                        <button
                                            onClick={() => {
                                                setShowListDevis(true)
                                                handleIsOpen()
                                            }}
                                        >
                                            Mes devis
                                        </button>
                                    </li>
                                )}
                                <li className='btn-primary'>
                                    <Link to='/devis' onClick={() => handleIsOpen()}>
                                        Générer un devis
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/faq' onClick={() => handleIsOpen()}>
                                        Faq
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/contact' onClick={() => handleIsOpen()}>
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </nav>

                        <div className='header_btn-right'>
                            {!user && (
                                <button
                                    onClick={() => {
                                        handleRegister()
                                        handleIsOpen()
                                    }}
                                >
                                    Crée un compte
                                </button>
                            )}

                            {!user && (
                                <button
                                    onClick={() => {
                                        handleLogin()
                                        handleIsOpen()
                                    }}
                                >
                                    Se connecter
                                </button>
                            )}

                            {user && (
                                <Avatar
                                    src={
                                        user.avatar?.data.attributes.pic.data.attributes.url !==
                                        undefined
                                            ? user.avatar.data.attributes.pic.data.attributes.url
                                            : null
                                    }
                                    alt={`Utillisateur ${user.username}`}
                                    width={50}
                                    height={50}
                                />
                            )}

                            {user && <Logout {...props} />}
                        </div>
                    </div>
                )}
            </header>
        </>
    )
}

export default HeaderMobile
