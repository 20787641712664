/* eslint-disable prettier/prettier */
import { appInterface, module_custon } from '../interface'
import User from './user'
import Api from './api'
import Devis from './devis'
import { SetStateAction } from 'react'
import sanitizeHtml from 'sanitize-html'
/**
 * generate name by infos devis
 * @param devis : module_custon.customDevi
 * @returns string
 */
class Logic {
    static getDevisName(devis: module_custon.customDevi): string {
        const data = devis.data.doc.doc_data
        const naneClient = (
            data.client.name +
            '-' +
            data.info.number +
            '-' +
            data.info.ref
        ).replace(' ', '_')
        return naneClient
    }
    static addLigne(): module_custon.Ligne {
        return {
            qte: '',
            description: '',
            price: '',
            montant: 0,
            remise: '',
        }
    }

    static getNextNumber(user: User | undefined | null): number {
        const count = user?.list_devis?.data.length || 0
        return count + 1
    }

    static getRefAndNumberDevis(user: User | null | undefined): { ref: string; number: number } {
        const number = Logic.getNextNumber(user)
        const ref = `DEV-${Logic.addZeroLeftNumber(number, 3)}`
        return { ref, number }
    }

    static newDevis(user: User | undefined | null): module_custon.customDevi {
        const devis = User.createDevi()

        devis.data.doc.doc_data.lignes = [Logic.addLigne()]

        if (user && user?.default_provider) {
            devis.data.provider = user.default_provider
        }

        const { ref, number } = Logic.getRefAndNumberDevis(user)
        devis.data.doc.doc_data.info.number = number
        devis.data.doc.doc_data.info.ref = ref

        const date = new Date()
        const day = Logic.addZeroLeftNumber(date.getDate(), 2)
        const month = Logic.addZeroLeftNumber(date.getMonth() + 1, 2)
        const year = date.getFullYear()

        devis.data.doc.doc_data.info.date = day + ' / ' + month + ' / ' + year

        return devis
    }

    private static addZeroLeftNumber(number: number, size: number) {
        let str = number.toString()

        if (str.length < size) {
            while (str.length < size) {
                str = '0' + str
            }
        }

        return str
    }
    static calculResult(
        ligne: module_custon.Ligne[],
        tva: number,
        setTotal: (value: {
            discount: number
            totalHT: number
            totalTTC: number
            totalTVA: number
        }) => void,
    ) {
        const result = {
            discount: 0,
            totalHT: 0,
            totalTTC: 0,
            totalTVA: 0,
        }
        ligne.forEach((l) => {
            const t = l.montant
            result.totalHT += t
            result.totalTVA += Math.round(((t * tva) / 100) * 100) / 100
            result.totalTTC = Math.round((result.totalHT + result.totalTVA) * 100) / 100

            result.discount +=
                Math.round(
                    ((parseFloat(l.qte) * parseFloat(l.price) * parseFloat(l.remise)) / 100) * 100,
                ) / 100
        })
        setTotal(result)
        return result
    }
    static sheckInputRequired(devis: module_custon.customDevi) {
        if (
            !devis.data.doc.doc_data.client.name ||
            devis.data.doc.doc_data.client.name === '' ||
            devis.data.doc.doc_data.client.name === ' '
        ) {
            throw new Error('client name is required')
        }
        if (
            !devis.data.provider.company_name ||
            devis.data.provider.company_name === '' ||
            devis.data.provider.company_name === ' '
        ) {
            throw new Error('provider name is required')
        }
        if (
            !devis.data.provider.email_pro ||
            devis.data.provider.email_pro === '' ||
            devis.data.provider.email_pro === ' '
        ) {
            throw new Error('provider email is required')
        }
        if (
            !devis.data.doc.doc_data.client.email ||
            devis.data.doc.doc_data.client.email === '' ||
            devis.data.doc.doc_data.client.email === ' '
        ) {
            throw new Error('client email is required')
        }
        /* check format email is not correcte return exption */
        User.checkEmailFormat(devis.data.provider.email_pro)
        User.checkEmailFormat(devis.data.doc.doc_data.client.email)

        return true
    }

    static async uploadFile(
        files: FileList,
        user: User | undefined | null,
        config: { api: appInterface.config },
    ): Promise<{ url: string; id: number }> {

        if (!user) {
            throw new Error('Please login to upload your logo')
        }
        const file: File = files[0]

        const data = new FormData()
        data.append('files', file)

        const logo = await Api.post(config.api.url, config.api.upload, data, user.jwt, false)

        if (logo.error) {
            throw new Error(logo.error.message)
        }

        const result = {
            url: logo[0].url,
            id: logo[0].id,
        }

        return result
    }

    static saveDevis(
        _devis: module_custon.customDevi,
        user: User | undefined | null,
        config: { api: appInterface.config },
        customTable: appInterface.Customtale,
        showAlert: (message: string, timer: number, type: string) => void,

        setCurentdevis: {
            (value: SetStateAction<module_custon.customDevi>): void
            (arg0: module_custon.customDevi): void
        },
        setShowLoginPanel: (value: boolean) => void,
        setShowRegisterPanel: (value: boolean) => void,
        setShowModal: (value: boolean) => void,
        setShowSaveProfile: (value: boolean) => void,
        setData: ((value: { id: number }) => void) | undefined,
    ) {
        try {
            if (Logic.sheckInputRequired(_devis)) {
                if (user) {
                    if (!user?.default_provider) {
                        setShowSaveProfile(true)
                        return
                    }

                    const newDevis: module_custon.customDevi = { ..._devis }
                    newDevis.data.doc.doc_data.customTable = customTable
                    newDevis.data.id_user = user?.id
                    newDevis.data.doc.name = Logic.getDevisName(newDevis)

                    /* check ref qnd nu;ber devis */
                    if (_devis.data.doc.doc_data.info.number === 1) {
                        const { ref, number } = Logic.getRefAndNumberDevis(user)
                        _devis.data.doc.doc_data.info.number = number
                        _devis.data.doc.doc_data.info.ref = ref
                    }

                    if (!newDevis.data.id) {
                        user?.addDevis({
                            data: {
                                provider: {
                                    email_pro: newDevis.data.provider.email_pro,
                                    company_name: newDevis.data.provider.company_name,
                                    address: newDevis.data.provider.address,
                                    siret: newDevis.data.provider.siret,
                                    zip_code: newDevis.data.provider.zip_code,
                                    city: newDevis.data.provider.city,
                                    mobile: newDevis.data.provider.mobile,
                                    phone: newDevis.data.provider.phone,
                                    fax: newDevis.data.provider.fax,
                                    logo: newDevis.data.provider.logo.id,
                                    tva: newDevis.data.provider.tva,
                                },
                                doc: {
                                    doc_data: newDevis.data.doc.doc_data,
                                    name: newDevis.data.doc.name,
                                    tag: newDevis.data.doc.tag,
                                },
                                id_user: newDevis.data.id_user,
                            },
                        })
                            .then(async (res: module_custon.customDevi) => {
                                if (res) {
                                    showAlert('Devis sauvegardé', 3000, 'success')
                                    user.list_devis = await Devis.getAllDevis(user.id, user.jwt)
                                    user.list_devis.data.forEach((d) => {
                                        if (d.attributes.doc.id === res.data.doc.id) {
                                            res.data.id = d.id
                                            if (setData) {
                                                setData({ id: d.id })
                                            }
                                            setCurentdevis(res)
                                        }
                                    })
                                }
                            })
                            .catch((err: Error) => {
                                let newMessage = 'Erreur lors de la sauvegarde du devis'
                                if (err.message === 'Network Error') {
                                    newMessage = 'Erreur de connexion'
                                }
                                if (err.message === 'provider.email_pro must be a valid email') {
                                    newMessage = 'E-mail du prestataire est incorrect'
                                }

                                showAlert(newMessage, 3000, 'error')
                                throw new Error(err.message)
                            })
                    } else {
                        user?.updateDevis(newDevis)
                            .then((res: boolean) => {
                                if (res) {
                                    showAlert('Devis sauvegardé', 3000, 'success')
                                    setCurentdevis(newDevis)
                                }
                            })
                            .catch((err: Error) => {
                                showAlert('Erreur lors de la sauvegarde', 3000, 'error')
                                throw new Error(err.message)
                            })
                    }
                } else {
                    setShowLoginPanel(true)
                    setShowRegisterPanel(false)
                    setShowModal(true)
                }
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            let m = error.message

            if (m === 'email is not valid') {
                m = 'Les deux adresses e-mail du devis doivent être valides'
            } else {
                m = "Veuillez remplir tous les champs marqués d'un * "
            }

            showAlert(m, 10000, 'error')
        }
    }
    static customsSanitizeHtml(value: string): string {
        let newValue = sanitizeHtml(value)
        if (
            newValue.includes('&lt;') ||
            newValue.includes('&gt;')
        ) {
            newValue = ''
        }
        return newValue
    }
}

export default Logic
