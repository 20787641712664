interface BtnProps {
    color: string
    callBack: Function
    children: React.ReactNode
    size?: string
    enabeld?: boolean
}
const Button: React.FC<BtnProps> = (props: BtnProps) => {
    const { color, callBack, size } = props
    const colorAtribute = { style: {} }
    let classBtn = ''
    switch (color) {
        case 'primary':
            if (props.enabeld === undefined || props.enabeld) {
                colorAtribute.style = { backgroundColor: '#6246EA' }
            } else {
                colorAtribute.style = { backgroundColor: 'rgba(98, 70, 234, 0.7)' }
            }

            break

        case 'secondary':
            if (props.enabeld === undefined || props.enabeld) {
                colorAtribute.style = { backgroundColor: '#E45858' }
            } else {
                colorAtribute.style = { backgroundColor: 'rgba(228, 88, 88, 0.7)' }
            }
            break
    }

    /* fixe undefined class btn */
    let curentSize: string | undefined = size
    if (curentSize === undefined || curentSize === 'null') {
        curentSize = ''
    }
    if (color === 'primary') {
        classBtn = ' btn-primary-hover'
    } else classBtn = ' btn-secondary-hover'

    return (
        <button
            type='button'
            className={'btn ' + curentSize + classBtn}
            style={colorAtribute.style}
            onClick={() => callBack()}
        >
            {props.children}
        </button>
    )
}
export default Button
