/* STYLE SCSS */
import 'react-toastify/dist/ReactToastify.css'
import './assets/scss/style.scss'
import Logo from './assets/img/logo/Logo.svg'

/* LIB */
import { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Routes, Route, HashRouter, useLocation } from 'react-router-dom'
import User from './util/user'
import { useCookies } from 'react-cookie'
import { ToastContainer, toast } from 'react-toastify'

/* PAGE AND COMPONENTS */
import { Header, Footer, Rgpd, Modal } from './components'
import { Home, Quotation, Contact, Legal, Profil, Faq, Page404 } from './pages'
import ResetPassword from './pages/resetPassword'

function App() {
    const [user, setUser] = useState<User | null | undefined>(undefined)
    const [cookies, setCookie, removeCookie] = useCookies(['user', 'rgpd'])
    const [showRgpdPopup, setShowRgpdPopup] = useState(false)
    const [data, setData] = useState<{ id: number }>({ id: 0 })
    // eslint-disable-next-line @typescript-eslint/no-empty-function

    const handleAlert = (message: string, timer: number, type: string) => {
        toast[type](message, {
            position: 'bottom-right',
            autoClose: timer,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'color',
        })
    }

    const AppProps = {
        user: user,
        showAlert: handleAlert,
        UpdateUser: (user: User | null) => {
            if (user !== null) {
                const rgpdCookies = cookies.rgpd
                let maxAge = 3600

                if (rgpdCookies === undefined) {
                    setShowRgpdPopup(true)
                } else {
                    switch (rgpdCookies) {
                        case 'accepted':
                            maxAge = 86400 * 7
                            break

                        default:
                            maxAge = 3600
                            break
                    }
                }

                setCookie('user', user?.jwt + '/' + user?.id, {
                    maxAge: maxAge,
                })
            } else {
                removeCookie('user')
            }
            setUser(user)
        },
        data,
        setData,
    }

    useEffect(() => {
        const userCookies = cookies.user
        const rgpdCookies = cookies.rgpd

        /* rgpd */
        if (rgpdCookies !== undefined && rgpdCookies === 'accepted') {
            setCookie('rgpd', 'accepted', {
                maxAge: 86400 * 7,
            })
        } else if (rgpdCookies === undefined) {
            setShowRgpdPopup(true)
        }

        if (userCookies !== undefined) {
            const userData = userCookies.split('/')

            const userJwt = userData[0]
            const userId = userData[1]

            User.getUserByIdandJwt(userId, userJwt)
                .then((user) => {
                    setUser(user)
                })
                .catch((err) => {
                    console.error(err)
                })
        } else {
            setUser(null)
        }
    }, [])

    return (
        <>
            <AnimatePresence>
                {user === undefined && (
                    <div
                        style={{
                            width: '100vw',
                            height: '100vh',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <motion.img
                            src={Logo}
                            width='200px'
                            height={'auto'}
                            loading={'lazy'}
                            alt='Logo du site affiché pendant le chargement de la page'
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, scale: 1.5 }}
                            transition={{ duration: 2 }}
                            exit={{ opacity: 0, scale: 1 }}
                        />
                    </div>
                )}
            </AnimatePresence>
            {user !== undefined && (
                <div className='App'>
                    <HashRouter>
                        <ScrollToTop />
                        <Header {...AppProps} />
                        {showRgpdPopup && (
                            <Modal isOpen={true} onClose={() => setShowRgpdPopup(false)}>
                                <Rgpd close={() => setShowRgpdPopup(false)} />
                            </Modal>
                        )}
                        <ToastContainer />
                        <Routes>
                            <Route path='/' element={<Home />} />

                            <Route path='/devis' element={<Quotation {...AppProps} />} />
                            <Route path='/contact' element={<Contact />} />
                            <Route path='/legal' element={<Legal />} />
                            <Route path='/profil' element={<Profil {...AppProps} />} />
                            <Route path='/faq' element={<Faq />} />
                            <Route
                                path='/resetpassword/:code'
                                element={<ResetPassword {...AppProps} />}
                            />

                            <Route path='*' element={<Page404 />} />
                        </Routes>

                        <Footer />
                    </HashRouter>
                </div>
            )}
        </>
    )
}
export default App

function ScrollToTop() {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return null
}
