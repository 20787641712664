import { Draggable } from 'react-beautiful-dnd'
import { FaHandSparkles } from 'react-icons/fa'
import { TiDelete } from 'react-icons/ti'

import { module_custon } from '../interface'
import Logic from '../util/logic'

interface Ligne {
    qte: string
    description: string
    price: string
    montant: number
    remise: string
}
interface CustomTable {
    devise: string
    discountPerLine: boolean
}
interface LigneTableProps {
    index: number
    ligne: Ligne
    curentDevis: module_custon.customDevi
    setCurentdevis: (value: module_custon.customDevi) => void
    onDelete: (index: number) => void
    customTable: CustomTable
}

const LigneTable = (props: LigneTableProps) => {
    const { index, curentDevis, setCurentdevis, onDelete, customTable } = props
    let { ligne } = props

    ligne = CheckLigne(ligne)
    ligne.montant = LigneCalcul(ligne, customTable)

    return (
        <Draggable draggableId={'drg_' + index} index={index}>
            {(provided) => (
                <tr ref={provided.innerRef} {...provided.draggableProps}>
                    <td {...provided.dragHandleProps} className='background-mobile'>
                        <span className='drag-icon'>
                            <FaHandSparkles />
                        </span>
                    </td>
                    <td data-label='quantité'>
                        <span
                            onClick={(event) => {
                                GetUserPresseEnter(event)
                            }}
                            onBlur={(value) => {
                                const newValue = ligneGetInput(value, /^[0-9]*$/)
                                value.currentTarget.innerText = newValue
                                setCurentdevis(
                                    UpdateLigne(
                                        ligne,
                                        newValue,
                                        index,
                                        curentDevis,
                                        customTable,
                                        'qte',
                                    ),
                                )
                            }}
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                        >
                            {ligne.qte}
                        </span>
                    </td>
                    <td style={{ textAlign: 'left' }} data-label='description :'>
                        <span
                            onBlur={(value) => {
                                const newDevis = { ...curentDevis }
                                newDevis.data.doc.doc_data.lignes[index].description =
                                    value.currentTarget.innerHTML

                                setCurentdevis(newDevis)
                            }}
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            dangerouslySetInnerHTML={{ __html: ligne.description }}
                        ></span>
                    </td>
                    <td data-label='prix unitaire'>
                        <span
                            onClick={(event) => {
                                GetUserPresseEnter(event)
                            }}
                            onBlur={(value) => {
                                const newValue = ligneGetInput(value, /^[0-9.]*$/)
                                value.currentTarget.innerText = newValue
                                setCurentdevis(
                                    UpdateLigne(
                                        ligne,
                                        newValue,
                                        index,
                                        curentDevis,
                                        customTable,
                                        'price',
                                    ),
                                )
                            }}
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                        >
                            {ligne.price}
                        </span>
                        {' ' + customTable.devise}
                    </td>
                    <td data-label='montant'>
                        {ligne.montant}
                        {' ' + customTable.devise}
                    </td>
                    {customTable.discountPerLine && (
                        <td data-label='remise'>
                            <span
                                onClick={(event) => {
                                    GetUserPresseEnter(event)
                                }}
                                onBlur={(value) => {
                                    const newValue = ligneGetInput(value, /^[0-9.]*$/)
                                    value.currentTarget.innerText = newValue
                                    setCurentdevis(
                                        UpdateLigne(
                                            ligne,
                                            newValue,
                                            index,
                                            curentDevis,
                                            customTable,
                                            'remise',
                                        ),
                                    )
                                }}
                                contentEditable={true}
                                suppressContentEditableWarning={true}
                            >
                                {ligne.remise}
                            </span>{' '}
                            %
                        </td>
                    )}
                    <td className='action action-mobile' data-label='supprimer'>
                        <TiDelete onClick={() => onDelete(index)} className='action-delete' />
                    </td>
                </tr>
            )}
        </Draggable>
    )
}

export default LigneTable

function LigneCalcul(ligne: Ligne, customTable: CustomTable): number {
    const { qte, price, remise } = ligne
    const newQte = qte.toString().match(/^[0-9]*$/) !== null ? parseInt(qte) : 0
    const newPrice = price.toString().match(/^[0-9.]*$/) !== null ? parseFloat(price) : 0
    const newCalcul: number = newQte * newPrice
    const montant = Math.round(newCalcul * 100) / 100
    let remiseMontant = 0
    if (customTable.discountPerLine) {
        remiseMontant = Math.round(montant * (parseFloat(remise) / 100) * 100) / 100
    }

    return montant - remiseMontant
}

function CheckLigne(ligne: Ligne): Ligne {
    if (!ligne.qte || ligne.qte === '' || ligne.qte === ' ') {
        ligne.qte = '0'
    }
    if (!ligne.description || ligne.description === '' || ligne.description === ' ') {
        ligne.description = ''
    }
    if (!ligne.price || ligne.price === '' || ligne.price === ' ') {
        ligne.price = '0'
    }
    if (!ligne.remise || ligne.remise === '' || ligne.remise === ' ') {
        ligne.remise = '0'
    }

    return ligne
}

function ligneGetInput(
    value: React.FocusEvent<HTMLSpanElement, Element>,
    regex: {
        [Symbol.match](string: string): RegExpMatchArray | null
    },
) {
    const newValue = Logic.customsSanitizeHtml(value.currentTarget.innerText)

    if (newValue.match(regex) !== null) {
        return newValue
    } else {
        return '0'
    }
}

function UpdateLigne(
    ligne: Ligne,
    value: string,
    index: number,
    curentDevis: module_custon.customDevi,
    customTable: CustomTable,
    target: string,
) {
    const newDevis = { ...curentDevis }
    newDevis.data.doc.doc_data.lignes[index][target] = Logic.customsSanitizeHtml(value)

    newDevis.data.doc.doc_data.lignes[index] = CheckLigne(newDevis.data.doc.doc_data.lignes[index])

    newDevis.data.doc.doc_data.lignes[index].montant = LigneCalcul(ligne, customTable)

    return newDevis
}

function GetUserPresseEnter(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    if (event.currentTarget.innerText === '0') {
        event.currentTarget.innerText = ''
    }
    event.currentTarget.addEventListener('keydown', (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            e.stopPropagation()
            e.currentTarget.blur()
        }
    })
}
