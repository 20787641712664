import React from 'react'
import Logic from '../util/logic'

interface Inputprops {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any | string
    placeholder: string
    name?: string
    label: string | null
    type: string
    error?: string
    callback?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const InputForm = (props: Inputprops) => {
    const { value, placeholder, name, label, type, callback } = props

    const handleInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (callback) {
            event.target.value = Logic.customsSanitizeHtml(event.target.value)

            callback(event)
        }
    }
    return (
        <>
            <div className={type !== 'checkbox' ? 'inputComponent' : 'checkboxComponent'}>
                {props.error && (
                    <div className='inputComponent__error checkboxComponent__error'>
                        {props.error}
                    </div>
                )}
                {label && <label htmlFor={name}>{label}</label>}
                {type !== 'textarea' ? (
                    <input
                        onChange={(e) => handleInput(e)}
                        placeholder={placeholder}
                        type={type}
                        id={name}
                        name={name}
                        value={value}
                    />
                ) : (
                    <textarea
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        onChange={(e) => handleInput(e)}
                    ></textarea>
                )}
            </div>
        </>
    )
}
export default InputForm
