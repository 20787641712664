import { Card, FormContact } from '../components'
import team1 from '../assets/img/team_jo.jpg'
import team2 from '../assets/img/team_ty.jpg'
import { Button } from '../components'

const Contact = () => {
    document.title = 'Contact'

    return (
        <section className='contact'>
            <div className='contact__hero'>
                <h1>Contact</h1>
                <p>Vous avez des questions ou autres contactez-nous ici</p>
            </div>
            <div className=' container contact__content'>
                <div className='contact__content-team padding-content'>
                    <h2>Notre équipe</h2>
                    <div className='contact__card'>
                        <Card
                            img={{
                                src: team1,
                                width: 89,
                                height: 89,
                                alt: 'Photo de profil du développeur back-end',
                            }}
                            job='Développeur back-end'
                            name='Jonathan Gauvin'
                            icons={[
                                { name: 'github', url: 'https://github.com/Jonathan97480' },
                                {
                                    name: 'linkedin',
                                    url: 'https://www.linkedin.com/in/gauvin-jonathan/',
                                },
                                { name: 'twitter', url: 'https://twitter.com/jonathanfrt9741' },
                            ]}
                            btn={
                                <Button
                                    color='primary'
                                    callBack={() => {
                                        const newWindow = window.open(
                                            'https://jon-dev.fr/',
                                            '_blank',
                                            'noopener,noreferrer',
                                        )
                                        if (newWindow) newWindow.opener = null
                                    }}
                                    size='btn--small'
                                >
                                    Voir portfolio
                                </Button>
                            }
                        />
                        {/* card2 */}
                        <Card
                            img={{
                                src: team2,
                                width: 89,
                                height: 89,
                                alt: 'Photo de profil du développeur front-end',
                            }}
                            job='Développeur front-end'
                            name='Teddy Equerre'
                            icons={[
                                { name: 'github', url: 'https://github.com/maakino' },
                                {
                                    name: 'linkedin',
                                    url: 'https://www.linkedin.com/in/teddy-equerre-514a781a2',
                                },
                                { name: 'twitter', url: 'https://twitter.com/TyDevelopper' },
                                {
                                    name: 'instagram',
                                    url: 'https://www.instagram.com/tydevelopper/',
                                },
                                { name: 'twitch', url: 'https://www.twitch.tv/maakyno' },
                            ]}
                            btn={
                                <Button
                                    color='primary'
                                    callBack={() => {
                                        const newWindow = window.open(
                                            'https://tydevelopper.fr/',
                                            '_blank',
                                            'noopener,noreferrer',
                                        )
                                        if (newWindow) newWindow.opener = null
                                    }}
                                    size='btn--small'
                                >
                                    Voir portfolio
                                </Button>
                            }
                        />
                    </div>
                </div>
                {/* contact input */}
                <div className='contact__form padding-content'>
                    <h2>Nous contacter</h2>
                    <FormContact />
                </div>
            </div>
        </section>
    )
}

export default Contact
