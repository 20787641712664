import React from 'react'
import { FaGithubSquare, FaLinkedin, FaTwitter, FaInstagram, FaTwitch } from 'react-icons/fa'

interface CardProps {
    img: {
        src: string
        width: number
        height: number
        alt: string
    }
    job: string
    name: string
    icons: { name: string; url: string }[]
    btn: JSX.Element
}
const Card = (props: CardProps) => {
    const { img, job, name, icons, btn } = props

    return (
        <>
            <div className='contact__card-content'>
                <img src={img.src} width={img.width} height={img.height} alt={img.alt} />
                <p>{name}</p>

                <p className='contact__card-job'>{job}</p>
                <IconList icons={icons} />
                {btn}
            </div>
        </>
    )
}

interface IconListProps {
    icons: { name: string; url: string }[]
}
const IconList = (props: IconListProps) => {
    const { icons } = props
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    const list: JSX.Element[] = icons.map((icon, index) => {
        switch (icon.name) {
            case 'github':
                return (
                    <FaGithubSquare
                        key={index}
                        style={{ color: '#333333' }}
                        onClick={() => openInNewTab(icon.url)}
                    />
                )
            case 'linkedin':
                return (
                    <FaLinkedin
                        key={index}
                        style={{ color: '#006AC1' }}
                        onClick={() => openInNewTab(icon.url)}
                    />
                )
            case 'twitter':
                return (
                    <FaTwitter
                        key={index}
                        style={{ color: '#90E1FF' }}
                        onClick={() => openInNewTab(icon.url)}
                    />
                )
            case 'instagram':
                return (
                    <FaInstagram
                        key={index}
                        style={{ color: '#F56040' }}
                        onClick={() => openInNewTab(icon.url)}
                    />
                )
            case 'twitch':
                return (
                    <FaTwitch
                        key={index}
                        style={{ color: '#874CF6' }}
                        onClick={() => openInNewTab(icon.url)}
                    />
                )
            default:
                return <></>
        }
    })
    return <div className='contact__card-icons'>{list}</div>
}

export default Card
