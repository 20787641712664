import React from 'react'
import User from '../util/user'
import { appInterface } from '../interface'
import Logic from '../util/logic'

interface forgotPasswordProps {
    app: appInterface.props
    onClose: () => void
}

const ForgotPassword = (props: forgotPasswordProps) => {
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const email = Logic.customsSanitizeHtml(e.currentTarget.email.value)
        User.forgotPassword(email)
            .then(() => {
                props.app.showAlert(
                    'Un email vous a été envoyé pour réinitialiser votre mot de passe',
                    5000,
                    'success',
                )
                props.onClose()
            })
            .catch((err: Error) => {
                throw new Error(err.message)
            })
    }

    return (
        <>
            <div className='forgot-content'>
                <p className='modal__content-title'>Mot de passe oblier</p>
                <form onSubmit={(e) => handleSubmit(e)} className='forgot-content__form'>
                    <input
                        type='email'
                        placeholder='Votre adresse e-mail'
                        name='email'
                        required={true}
                    />
                    <input type='submit' value='Envoyer' />
                </form>
            </div>
        </>
    )
}

export default ForgotPassword
