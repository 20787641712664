import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'

interface RgpdProps {
    close: () => void
}

const Rgpd = (props: RgpdProps) => {
    const navigate = useNavigate()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookies, setCookie] = useCookies(['rgpd'])
    return (
        <div className='rgpd'>
            <p>
                Nous utilisons des cookies pour afficher des publicités et pour vous reconnecter
                automatiquement sur notre site. Vous pouvez accepter ou refuser ces cookies
                conformément à la loi européenne. Vous pouvez consulter nos mentons légaux{' '}
                <button onClick={() => navigate('/legal')}>ici</button>
            </p>

            <div className='rgpd__btn'>
                <button
                    onClick={() => {
                        setCookie('rgpd', 'denied', {
                            maxAge: 3600,
                        })
                        props.close()
                    }}
                >
                    Je refuse
                </button>
                <button
                    onClick={() => {
                        setCookie('rgpd', 'accepted', {
                            maxAge: 86400 * 7,
                        })
                        props.close()
                    }}
                >
                    J&apos;accepte
                </button>
            </div>
        </div>
    )
}

export default Rgpd
