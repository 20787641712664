const config = {
    api: {
        url: 'https://api249748.devispower.fr/',
        name: 'api/devis/',
        user: 'api/users/',
        avatar: 'api/avatars/',
        devis: 'api/devis/',
        auth: 'api/auth/local',
        register: 'api/auth/local/register',
        upload: 'api/upload/',
        urlBase: 'https://api249748.devispower.fr',
        imageFile: 'api/upload/files/',
        forgot_password: 'api/auth/forgot-password',
        reset_password: 'api/auth/reset-password',
    },
}

export default config
