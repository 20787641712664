/* eslint-disable prettier/prettier */
import { FaChevronUp } from 'react-icons/fa'
import { motion, AnimatePresence } from 'framer-motion'
import { useState } from 'react'

interface AccordionProps {
    label: string

    children?: React.ReactNode
    classNames?: string
}

const Accordion = (props: AccordionProps) => {
    const [isActive, setIsActive] = useState(false)

    const iconDown: React.CSSProperties = {
        transform: 'rotate(180deg)',
        transition: 'all 0.2s ease-in-out',
    }
    const iconUp: React.CSSProperties = {
        transform: 'rotate(0deg)',
        transition: 'all 0.3s ease-in-out',
    }
    const icon: React.CSSProperties = !isActive ? iconDown : iconUp

    return (
        <>
            <div className={props.classNames}>
                <button
                    className={
                        props.classNames +
                        '__btn ' +
                        (isActive ? props.classNames + '__active' : '')
                    }
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                    onClick={() => setIsActive(!isActive)}
                >
                    {props.label}

                    <span>
                        <FaChevronUp style={icon} />
                    </span>
                </button>
                <AnimatePresence>
                    {isActive && (
                        <motion.div
                            initial={{ height: 0 }}
                            animate={{ height: 'auto' }}
                            transition={{ duration: 0.3 }}
                            exit={{ height: 0 }}
                            className={props.classNames + '__panel'}
                        >
                            {props.children}
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </>
    )
}

export default Accordion
