import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import Logic from '../util/logic'
import { Button, LigneTable } from '../components'
import { module_custon } from '../interface'

interface TableDevisProps {
    curentDevis: module_custon.customDevi
    setCurentdevis: (devis: module_custon.customDevi) => void
    customTable: {
        colorHeader: string
        colortextHeader: string
        colorLigne: string
        colortextLigne: string
        colorLigne2: string
        colortextLigne2: string
        devise: string
        discountPerLine: boolean
        tva: boolean
    }
    setTotal: (value: {
        discount: number
        totalHT: number
        totalTVA: number
        totalTTC: number
    }) => void
}

const TableDevis = (props: TableDevisProps) => {
    const { setCurentdevis, curentDevis, customTable, setTotal } = props

    const handleTablePositionLigne = (event: DropResult) => {
        if (event.destination) {
            const newDevis: module_custon.customDevi = { ...curentDevis }
            const [selectedRow] = newDevis.data.doc.doc_data.lignes.splice(event.source.index, 1)

            newDevis.data.doc.doc_data.lignes.splice(event.destination?.index, 0, selectedRow)
            setCurentdevis(newDevis)
        }
    }

    return (
        <DragDropContext onDragEnd={(event) => handleTablePositionLigne(event)}>
            <Button
                color={'primary'}
                callBack={() => {
                    const d = { ...curentDevis }
                    d.data.doc.doc_data.lignes.push(Logic.addLigne())
                    setCurentdevis(d)
                }}
            >
                Ajouter une ligne
            </Button>

            <table className='devis-table'>
                <thead
                    style={{
                        backgroundColor: customTable.colorHeader,
                        color: customTable.colortextHeader,
                    }}
                >
                    <tr>
                        <th className='action'></th>
                        <th>Quantité</th>
                        <th>Description</th>
                        <th>Prix unitaire</th>
                        <th>Montant</th>
                        {customTable.discountPerLine && <th>Remise</th>}
                        <th className='action'>Action</th>
                    </tr>
                </thead>
                <Droppable droppableId='tbody'>
                    {(provided) => (
                        <tbody
                            data-col1={customTable.colorLigne}
                            data-col={customTable.colorLigne2}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {curentDevis.data.doc.doc_data.lignes.map((ligne, index: number) => (
                                // eslint-disable-next-line react/jsx-key
                                <LigneTable
                                    key={index}
                                    index={index}
                                    ligne={ligne}
                                    customTable={{
                                        devise: customTable.devise,
                                        discountPerLine: customTable.discountPerLine,
                                    }}
                                    curentDevis={curentDevis}
                                    setCurentdevis={(devis) => {
                                        Logic.calculResult(
                                            devis.data.doc.doc_data.lignes,
                                            devis.data.doc.doc_data.provider.tva,
                                            setTotal,
                                        )
                                        console.log(devis)
                                        setCurentdevis(devis)
                                    }}
                                    onDelete={() => {
                                        const c = { ...curentDevis }
                                        c.data.doc.doc_data.lignes.splice(index, 1)
                                        setCurentdevis(c)
                                        Logic.calculResult(
                                            curentDevis.data.doc.doc_data.lignes,
                                            curentDevis.data.doc.doc_data.provider.tva,
                                            setTotal,
                                        )
                                    }}
                                />
                            ))}
                            {provided.placeholder}
                        </tbody>
                    )}
                </Droppable>
            </table>
        </DragDropContext>
    )
}

export default TableDevis
