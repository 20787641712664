import { useState } from 'react'
import { RiMenuAddLine } from 'react-icons/ri'
import Logic from '../util/logic'

interface InfoCustomerAndProviderProps {
    label?: string
    name?: string
    address?: string
    zipCode?: string
    city?: string
    phone?: string
    fax?: string
    mobile?: string
    email?: string
    siret?: string
    onChange: (value: {
        name: string
        address: string
        zipCode: string
        city: string
        phone: string
        fax: string
        mobile: string
        email: string
        siret: string
    }) => void
}

const InfoCustomerAndProvider = (props: InfoCustomerAndProviderProps) => {
    const info = {
        name: props.name || '',
        address: props.address || '',
        zipCode: props.zipCode || '',
        city: props.city || '',
        phone: props.phone || '',
        fax: props.fax || '',
        mobile: props.mobile || '',
        email: props.email || '',
        siret: props.siret || '',
    }

    const [viewLigne, setViewLigne] = useState({
        phone: info.phone === '' ? false : true,
        mobile: info.mobile === '' ? false : true,
        fax: info.fax === '' ? false : true,
        siret: info.siret === '' ? false : true,
        email: true,
        zipCode: true,
        city: true,
        address: true,
        name: true,
    })

    const [showChoice, setShowChoice] = useState(false)
    return (
        <div className='devis__infos-provider'>
            {viewLigne.name && (
                <LigneInfo
                    info={info}
                    onchange={props.onChange}
                    custom_key={'name'}
                    label={props.label ? props.label + ' ' : ''}
                    required={true}
                />
            )}
            {viewLigne.address && (
                <LigneInfo
                    info={info}
                    onchange={props.onChange}
                    custom_key={'address'}
                    label='Adresse '
                    required={true}
                />
            )}
            {viewLigne.city && (
                <LigneInfo
                    info={info}
                    onchange={props.onChange}
                    custom_key={'city'}
                    label='Ville '
                    required={true}
                />
            )}
            {viewLigne.zipCode && (
                <LigneInfo
                    info={info}
                    onchange={props.onChange}
                    custom_key={'zipCode'}
                    label='Code postal '
                    required={true}
                />
            )}
            {viewLigne.siret && (
                <LigneInfo
                    info={info}
                    onchange={props.onChange}
                    custom_key={'siret'}
                    label='Siret N° '
                />
            )}
            {viewLigne.email && (
                <LigneInfo
                    info={info}
                    onchange={props.onChange}
                    custom_key={'email'}
                    label='E-mail '
                    required={true}
                />
            )}
            {viewLigne.phone && (
                <LigneInfo info={info} onchange={props.onChange} custom_key={'phone'} label='Tel' />
            )}
            {viewLigne.mobile && (
                <LigneInfo
                    info={info}
                    onchange={props.onChange}
                    custom_key={'mobile'}
                    label='Mobile '
                />
            )}
            {viewLigne.fax && (
                <LigneInfo info={info} onchange={props.onChange} custom_key={'fax'} label='Fax' />
            )}{' '}
            <div className='provider-add-content' onClick={() => setShowChoice(!showChoice)}>
                <span>Ajouter autres infos</span>
                <RiMenuAddLine className='provider-add-icon' />
            </div>
            {showChoice && (
                <SelectLigneInfo
                    setViewLigne={setViewLigne}
                    viewLigne={viewLigne}
                    info={info}
                    setShowChoice={setShowChoice}
                    onchange={props.onChange}
                />
            )}
        </div>
    )
}

interface LigneInfoProps {
    info: {
        name: string
        address: string
        zipCode: string
        city: string
        phone: string
        fax: string
        mobile: string
        email: string
        siret: string
    }
    label: string
    custom_key: string
    onchange: (value: {
        name: string
        address: string
        zipCode: string
        city: string
        phone: string
        fax: string
        mobile: string
        email: string
        siret: string
    }) => void
    required?: boolean
}

const LigneInfo = (props: LigneInfoProps) => {
    const { info, onchange, label, custom_key } = props
    let value = ''
    switch (custom_key) {
        case 'name':
            value = ' ' + info.name

            break
        case 'address':
            value = ' ' + info.address
            break
        case 'zipCode':
            value = ' ' + info.zipCode
            break
        case 'city':
            value = ' ' + info.city
            break
        case 'phone':
            value = ' ' + info.phone
            break
        case 'fax':
            value = ' ' + info.fax
            break
        case 'mobile':
            value = ' ' + info.mobile
            break
        case 'email':
            value = ' ' + info.email
            break
        case 'siret':
            value = ' ' + info.siret
            break
    }

    return (
        <p>
            {props.required ? <span style={{ color: 'red', fontSize: '1.6rem' }}>*</span> : null}{' '}
            {label}:
            <span
                className='helement-edit'
                contentEditable={true}
                suppressContentEditableWarning={true}
                onBlur={(e) => {
                    const i = { ...info }
                    e.target.innerText = Logic.customsSanitizeHtml(e.target.innerText)
                    switch (custom_key) {
                        case 'name':
                            i.name = e.target.innerText

                            break
                        case 'address':
                            i.address = e.target.innerText
                            break
                        case 'zipCode':
                            i.zipCode = e.target.innerText
                            break
                        case 'city':
                            i.city = e.target.innerText
                            break
                        case 'phone':
                            i.phone = e.target.innerText
                            break
                        case 'fax':
                            i.fax = e.target.innerText
                            break
                        case 'mobile':
                            i.mobile = e.target.innerText
                            break
                        case 'email':
                            i.email = e.target.innerText
                            break
                        case 'siret':
                            i.siret = e.target.innerText
                            break
                    }

                    onchange(i)
                }}
            >
                {value}
            </span>
        </p>
    )
}

interface SelectLigneInfoProps {
    viewLigne: {
        phone: boolean
        mobile: boolean
        fax: boolean
        email: boolean
        siret: boolean
        zipCode: boolean
        city: boolean
        address: boolean
        name: boolean
    }
    setViewLigne: (value: {
        phone: boolean
        mobile: boolean
        fax: boolean
        email: boolean
        siret: boolean
        zipCode: boolean
        city: boolean
        address: boolean
        name: boolean
    }) => void
    info: {
        name: string
        address: string
        zipCode: string
        city: string
        phone: string
        fax: string
        mobile: string
        email: string
        siret: string
    }
    setShowChoice(value: boolean): void
    onchange: (value) => void
}

const SelectLigneInfo = (props: SelectLigneInfoProps) => {
    const { setViewLigne, viewLigne, info, setShowChoice } = props

    const isShow = { ...viewLigne }

    return (
        <div className='small-popin'>
            <div className='small-popin__header'>
                <label>Ajouter d&lsquo;autres types de coordonnées</label>
            </div>
            <div className='small-popin__item'>
                <label>Fax</label>
                <input
                    checked={isShow.fax ? true : false}
                    onChange={(e) => {
                        isShow.fax = e.target.checked
                        if (!e.target.checked) {
                            info.fax = ''
                            props.onchange(info)
                        }
                        setShowChoice(false)
                        setViewLigne(isShow)
                    }}
                    type='checkbox'
                />
            </div>
            <div className='small-popin__item'>
                <label>Telephone</label>
                <input
                    checked={isShow.phone ? true : false}
                    onChange={(e) => {
                        isShow.phone = e.target.checked
                        if (!e.target.checked) {
                            info.phone = ''
                            props.onchange(info)
                        }
                        setShowChoice(false)
                        setViewLigne(isShow)
                    }}
                    type='checkbox'
                />
            </div>
            <div className='small-popin__item'>
                <label>Mobile</label>

                <input
                    checked={isShow.mobile ? true : false}
                    onChange={(e) => {
                        isShow.mobile = e.target.checked
                        if (!e.target.checked) {
                            info.mobile = ''
                            props.onchange(info)
                        }
                        setShowChoice(false)
                        setViewLigne(isShow)
                    }}
                    type='checkbox'
                />
            </div>
            <div className='small-popin__item'>
                <label>Siret</label>
                <input
                    checked={isShow.siret ? true : false}
                    onChange={(e) => {
                        isShow.siret = e.target.checked
                        if (!e.target.checked) {
                            info.siret = ''
                            props.onchange(info)
                        }
                        setShowChoice(false)
                        setViewLigne(isShow)
                    }}
                    type='checkbox'
                />
            </div>
        </div>
    )
}

export default InfoCustomerAndProvider
