/* eslint-disable prettier/prettier */
import config from '../config/conf'
import Api from './api'
import Devis from './devis'

import {
    apiAvatar,
    apiDefault_provider,
    apiDevis,
    apiDevisList,
    apiHttp,
    apiUser,
} from '../interface/apiInterface'
/**
 * create a user object
 * @param module_user.user
 * @returns User
 */

class User extends Devis {
    username: string
    email: string
    default_provider: apiDefault_provider.inputProvider | null = null
    avatar: apiAvatar.inputAvatar | null = null
    private _user: apiUser.User

    constructor(data: apiUser.inputUser) {
        super(data)

        this.username = data.user.username
        this.email = data.user.email
        this.jwt = data.jwt
        this._user = data.user
    }

    /**
     * return the user info base
     * @returns object
     */
    getUser() {
        return {
            id: this.id,
            name: this.username,
            email: this.email,
            jwt: this.jwt,
        }
    }
    static async refrechUser(jwt: string, id: number): Promise<User> {
        return await User.getUserByIdandJwt(id, jwt)
    }
    /**
     *
     * @param id : number
     * @param jwt : string
     * @returns
     */
    static async getUserByIdandJwt(id: number, jwt: string) {
        const userC: apiUser.User = await Api.get(
            `${config.api.url}`,
            `${config.api.user + id}`,
            jwt,
        )

        if (userC.error) {
            throw new Error(userC.error.message)
        }
        const newUser: apiUser.inputUser = {
            jwt: jwt,
            error: null,
            user: {
                ...userC,
            },
        }

        /* Get deault provider user */
        const user_default_provider = await Api.get(
            `${config.api.url}`,
            ` ${config.api.user}${newUser.user.id}?populate[0]=default_provider&populate[1]=default_provider.logo`,
            newUser.jwt,
        )

        if (user_default_provider.error) {
            throw new Error(user_default_provider.error.message)
        }

        const provider: apiDefault_provider.inputProvider = user_default_provider.default_provider

        /* Get avatar User */
        const user_avatar: apiAvatar.inputAvatar = await Api.get(
            `${config.api.url}`,
            ` ${config.api.avatar + newUser.user.id_avatar}`,
            newUser.jwt,
        )
        if (user_avatar.error) {
            if (user_avatar.error.message !== 'Not Found') {
                throw new Error(user_avatar.error.message)
            }
        }

        const user_list_devis: apiDevisList.inputDevisList = await Devis.getAllDevis(
            newUser.user.id,
            newUser.jwt,
        )

        const userCurent = new User(newUser)

        userCurent.avatar = user_avatar
        userCurent.list_devis = user_list_devis
        userCurent.default_provider = provider

        return userCurent
    }

    /**
     * Return the provider of the user
     * @returns module_default_provider.Provider | null
     */
    getDefaultProvider() {
        return this.default_provider
    }

    /**
     * Return user id
     * @returns number
     */
    getId(): number {
        return this.id
    }

    /**
     * Return all user info
     * @returns object
     */
    getAllinfoUser() {
        return {
            id: this.id,
            username: this.username,
            email: this.email,
            jwt: this.jwt,
            default_provider: this.default_provider,
            avatar: this.avatar,
            _user: this._user,
            list_devis: this.list_devis,
        }
    }
    async deleteUser(passworUser: string) {
        return await new Promise((resolve, reject) => {
            passworUser = User.checkPassword(passworUser)

            /* get password is correct */
            User.login({ identifier: this.username, password: passworUser })
                .then((result) => {
                    if (result) {
                        Devis.getAllDevis(this.id, this.jwt)
                            .then((result) => {
                                if (result !== null) {
                                    for (let index = 0; index < result.data.length; index++) {
                                        const element = result.data[index]
                                        this.removeDevis(element.id)
                                            .then(() => {
                                                console.log(
                                                    'devis number ' + (index + 1) + 'delete',
                                                )
                                            })
                                            .catch((error) => {
                                                reject(error)
                                            })
                                    }
                                } else {
                                    console.warn('no devi delete')
                                }
                            })
                            .catch((error: Error) => {
                                throw new Error(error.message)
                            })

                        if (this.avatar && this.avatar.data && this.avatar.data.id !== null) {
                            this.delete_avatar()
                                .then(() => {
                                    console.log('avatar delete')
                                })
                                .catch((error) => {
                                    reject(error)
                                })
                        } else {
                            console.warn('no avatar delete')
                        }
                        Api.delete(config.api.url, config.api.user + this.id, this.jwt)
                            .then(() => {
                                resolve(true)
                            })
                            .catch((error) => {
                                reject(error)
                            })
                    } else {
                        throw new Error('Error request delete all info user ')
                    }
                })
                .catch((error) => reject(error))
        })
    }
    /**
     * set user profil info
     * @param {username,email,password,password2}
     * @returns Promise<boolean>
     */
    async setProfilUser({
        username = '',
        email = '',
        password = '',
        password2 = '',
    }): Promise<boolean> {
        username = User.checkUserName(username)
        email = User.checkEmailFormat(email)

        if (password !== '' && password2 !== '') {
            password = User.checkPassword(password, password2)
        }

        const dataSend =
            password === ''
                ? {
                      username,
                      email,
                  }
                : {
                      username,
                      email,
                      password,
                  }

        const setUsser = await Api.put(
            config.api.url,
            config.api.user + this.id,
            JSON.stringify(dataSend),
            this.jwt,
        )

        if (setUsser.error) {
            throw new Error(setUsser.error.message)
        }

        return true
    }

    /**
     *  Update the user default provider
     * @param param0 {address:string, city:string, zipcode:string, siret:string, email:string, phone:string, name:string,tva:number}:module_devis.Provider2
     * @returns  Promise<boolean>
     */
    async setDefaultProvider({
        address = '',
        city = '',
        zip_code = '',
        siret = '',
        phone = '',
        mobile = '',
        email_pro = '',
        fax = '',
        tva = 0,
        company_name = '',
    }: apiDevis.Provider): Promise<boolean> {
        const new_default_provider = {
            default_provider: {
                address,
                city,
                zip_code,
                siret,
                mobile,
                phone,
                email_pro,
                fax,
                tva,
                company_name,
                logo: this.default_provider?.logo ? this.default_provider?.logo.id : null,
            },
        }

        /* call api */
        const res: apiHttp.Http_error = await Api.put(
            `${config.api.url}`,
            ` ${config.api.user + this.id}`,
            JSON.stringify(new_default_provider),
            this.jwt,
        )

        if (res.error) {
            throw new Error(res.error.message)
        }

        return true
    }

    /**
     * Registering a new user in the api returns an object of type User
     * @param {name : string, description : string, price : number, quantity : number, id_provider : number}
     * @returns Promise<User|null>
     */
    static async register({
        username = '',
        email = '',
        password = '',
        password_confirm = '',
    }): Promise<User | null> {
        email = this.checkEmailFormat(email)
        password = this.checkPassword(password, password_confirm)
        username = this.checkUserName(username)

        const data = new FormData()

        data.append('username', username)
        data.append('email', email)
        data.append('password', password)

        /* call api */
        const user: apiUser.inputUser = await Api.post(
            `${config.api.url}`,
            ` ${config.api.register}`,
            data,
            '',
            false,
        )

        if (user.error) {
            throw new Error(user.error.message)
        }

        const new_user = new User(user)

        return new_user
    }
    static async rezetPassword(
        code: string,
        password: string,
        password_confirm: string,
    ): Promise<boolean> {
        console.log(code.split('=')[1], password, password_confirm)
        const newPassword = User.checkPassword(password, password_confirm)

        const data = new FormData()

        data.append('code', code.split('=')[1])
        data.append('password', newPassword)
        data.append('passwordConfirmation', newPassword)

        const response = await Api.post(
            `${config.api.url}`,
            ` ${config.api.reset_password}`,
            data,
            '',
            false,
        )
        if (response.error) {
            throw new Error(response.error.message)
        }
        return true
    }

    static async forgotPassword(email: string) {
        const newForm = new FormData()
        newForm.append('email', email)
        const response = await Api.post(
            config.api.url,
            config.api.forgot_password,
            newForm,
            '',
            false,
        )

        if (response.error) {
            throw new Error(response.error.message)
        }
        return response
    }
    /**
     *  ceck is correct format email
     * @param email
     * @returns email
     */
    static checkEmailFormat(email: string): string {
        /* get is email format is correct */
        const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
        if (!isEmail) {
            throw new Error('email is not valid')
        }
        return email
    }
    /**
     * check is password correct
     * @param password
     * @param password2
     * @returns password
     */
    static checkPassword(password: string, password2?: string): string {
        if (password2) {
            if (password === null || password2 === null || password === '' || password2 === '') {
                throw new Error('two password missing required fields')
            }

            if (password !== password2) {
                throw new Error('the two passwords are not the same')
            }
        } else {
            if (password === null || password2 === '') {
                throw new Error('password missing required fields')
            }
        }

        const is_password = /^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[!#$%&? "]).*$/.test(password)
        if (!is_password) {
            throw new Error('password is not valid')
        }

        return password
    }

    /**
     * check user name correct
     * @param username
     * @returns username
     */
    static checkUserName(username: string): string {
        if (username === null || username === '') {
            throw new Error('missing required fields')
        }

        /* get is user name is correct */
        const is_identifier = /^[a-zA-Z]{3,}$/.test(username)
        if (!is_identifier) {
            throw new Error('username is not valid')
        }

        return username
    }
    /**
     * login to the api and return an object of type User
     * @param param {identifier:string, password:string}}
     * @returns Promise<User>
     */
    static async login({ identifier = '', password = '' }): Promise<User> {
        password = this.checkPassword(password)

        identifier = this.checkUserName(identifier)

        const data = new FormData()
        data.append('identifier', identifier)
        data.append('password', password)
        /* call api */
        const user: apiUser.inputUser = await Api.post(
            `${config.api.url}`,
            ` ${config.api.auth}`,
            data,
            '',
            false,
        )

        if (user.error) {
            throw new Error(user.error.message)
        }

        /* Get deault provider user */
        const user_default_provider = await Api.get(
            `${config.api.url}`,
            ` ${config.api.user}${user.user.id}?populate[0]=default_provider&populate[1]=default_provider.logo`,
            user.jwt,
        )

        if (user_default_provider.error) {
            throw new Error(user_default_provider.error.message)
        }

        const provider: apiDefault_provider.inputProvider = user_default_provider.default_provider

        /* Get avatar User */
        const user_avatar: apiAvatar.inputAvatar = await Api.get(
            `${config.api.url}`,
            ` ${config.api.avatar + user.user.id_avatar}`,
            user.jwt,
        )
        if (user_avatar.error) {
            if (user_avatar.error.message !== 'Not Found') {
                throw new Error(user_avatar.error.message)
            }
        }

        /* Get list devis User */
        const user_list_devis: apiDevisList.inputDevisList = await Api.get(
            `${config.api.url}`,
            ` ${config.api.devis + `?filters[id_user]=${user.user.id}`}`,
            user.jwt,
        )
        if (user_list_devis.error) {
            if (user_list_devis.error.message !== 'Not Found') {
                throw new Error(user_list_devis.error.message)
            }
        }

        const userCurent = new User(user)
        userCurent.avatar = user_avatar
        userCurent.list_devis = user_list_devis
        userCurent.default_provider = provider

        return userCurent
    }

    /**
     * retunr object of type avatar of the user
     * @returns Promise<module_avatar.avatar | null>
     */
    async get_avatar(): Promise<apiAvatar.inputAvatar | null> {
        const avatar: apiAvatar.inputAvatar = await Api.get(
            `${config.api.url}`,
            ` ${config.api.avatar + this._user.id_avatar}`,
            this.jwt,
        )
        if (avatar.error) {
            if (avatar.error.message !== 'Not Found') {
                throw new Error(avatar.error.message)
            }
            return null
        }
        this.avatar = avatar
        return avatar
    }

    /**
     *
     *Add the company logo to a quote or ho provider user
     * @param _file_logo : File
     * @param _id_devi : number
     * @param _is_defautl_provider : bollean
     * @returns Promise<string>
     */
    async addLogoCompany(
        _file_logo: File,
        _id_devi_or_user: number,
        _is_defautl_provider = false,
    ): Promise<string> {
        const data = new FormData()

        data.append('files', _file_logo)

        const target: string = _is_defautl_provider
            ? config.api.user + _id_devi_or_user
            : config.api.devis + _id_devi_or_user

        const logo_company = await Api.post(
            `${config.api.url}`,
            ` ${config.api.upload}`,
            data,
            this.jwt,
            false,
        )

        if (logo_company.error) {
            throw new Error(logo_company.error.message)
        }

        if (this.default_provider?.logo) {
            this.deleteImgDatabase(this.default_provider.logo.id)
        }

        const devis_logo = _is_defautl_provider
            ? {
                  default_provider: {
                      ...this.default_provider,
                      logo: logo_company[0].id,
                  },
              }
            : {
                  data: {
                      provider: {
                          ...this.default_provider,
                          logo: logo_company[0].id,
                      },
                  },
              }

        const res: apiHttp.Http_error = await Api.put(
            config.api.url,
            target,
            JSON.stringify(devis_logo),
            this.jwt,
        )

        if (res.error) {
            throw new Error(res.error.message)
        }

        return logo_company[0].url
    }

    private async deleteImgDatabase(idPicture: number) {
        const res = await Api.delete(config.api.url, config.api.imageFile + idPicture, this.jwt)

        if (res.error) {
            throw new Error(res.error.message)
        }

        return true
    }
    /**
     * delete avatar of the user and return true if success
     * @returns Promise<boolean>
     */
    async delete_avatar(): Promise<boolean> {
        const avatar: apiAvatar.inputAvatar = await Api.delete(
            `${config.api.url}`,
            ` ${config.api.avatar + this._user.id_avatar}`,
            this.jwt,
        )
        if (avatar.error) {
            throw new Error(avatar.error.message)
        }

        /* update id avatar user */
        const user: apiAvatar.inputAvatar = await Api.put(
            `${config.api.url}`,
            ` ${config.api.user + this._user.id}`,
            JSON.stringify({ id_avatar: null }),
            this.jwt,
        )
        if (this.avatar?.data) {
            this.deleteImgDatabase(this.avatar?.data.id)
        }

        if (user.error) {
            throw new Error(user.error.message)
        }

        this._user.id_avatar = null
        this.avatar = null

        return true
    }

    /**
     * add avatar of the user and return avatar if success
     * @param file file to upload
     * @returns Promise<module_avatar.avatar | null>
     */
    async add_avatar(file: File | null): Promise<string | null> {
        if (!file) {
            throw new Error('file not found')
        }

        if (this.avatar?.data) {
            /* delete old avatar  */
            await this.delete_avatar()
        }

        const data = new FormData()
        data.append('files', file)
        const avatarArray = await Api.post(
            `${config.api.url}`,
            ` ${config.api.upload}`,
            data,
            this.jwt,
            false,
        )

        if (avatarArray.error) {
            throw new Error(avatarArray.error.message)
        }
        const avatar = avatarArray[0]
        const avatar_prototype = await Api.post(
            `${config.api.url}`,
            ` ${config.api.avatar}`,
            JSON.stringify({ data: { pic: avatar.id } }),
            this.jwt,
        )
        if (avatar_prototype.error) {
            throw new Error(avatar_prototype.error.message)
        }

        /* update id avatar user */
        const user: apiUser.inputUser = await Api.put(
            `${config.api.url}`,
            ` ${config.api.user + this._user.id}`,
            JSON.stringify({
                id_avatar: avatar_prototype.data.id,
            }),
            this.jwt,
        )
        if (user.error) {
            throw new Error(user.error.message)
        }
        this._user.id_avatar
        this._user.id_avatar = avatar_prototype.data.id.toString()
        /*  this.avatar?.data.attributes.pic.data.attributes = avatar */

        return avatar.url
    }

    /**
     * Update avatar of the user and return avatar if success
     * @param file file to upload
     * @returns Promise<module_avatar.avatar | null>
     */
    async update_avatar(file: File): Promise<string | null> {
        this.delete_avatar()

        const avatar: string | null = await this.add_avatar(file)

        return avatar
    }
}

export default User
