import User from '../util/user'
import { apiDevisList } from '../interface'
import Modal from './modal'
import { useNavigate, useLocation } from 'react-router'
import { useState } from 'react'
import Devis from '../util/devis'
import Loading from './loading'

/* popup my quotation */
interface listDevisProps {
    _listquotation: apiDevisList.inputDevisList | null | undefined
    user: User | null | undefined
    setShowListDevis: (value: boolean) => void
    closeModal: () => void
    data: { id: number }
    setData: (value: { id: number }) => void
}

const QuotationList = (props: listDevisProps) => {
    return <GetModalDevis {...props} />
}

const CreateQuotationList = (props: listDevisProps) => {
    const { _listquotation, closeModal } = props
    const [listquotation, setListquotation] = useState(_listquotation)

    return (
        <table className='table'>
            <thead className='table__content'>
                <tr>
                    <th>Nom du devis</th>
                    <th className='tableMobile'>Date</th>
                    <th className='tableMobile'>Référence</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {listquotation?.data.map((quotation, index) => {
                    return (
                        <Ligne
                            key={index}
                            quotation={quotation}
                            setListquotation={setListquotation}
                            closeModal={closeModal}
                            setData={props.setData}
                            data={props.data}
                            user={props.user}
                            index={index}
                        />
                    )
                })}
            </tbody>
        </table>
    )
}

const GetModalDevis = (props: listDevisProps): JSX.Element => {
    const { user, setShowListDevis } = props

    if (!user?.list_devis || user?.list_devis.data.length < 1) {
        return (
            <Modal
                isOpen={true}
                onClose={() => {
                    setShowListDevis(false)
                }}
            >
                <div className='noQuotation'>
                    <p>Vous n’avez pas de devis sauvegarder pour le moment</p>
                    <button
                        onClick={() => {
                            setShowListDevis(false)
                        }}
                    >
                        Ok
                    </button>
                </div>
            </Modal>
        )
    } else {
        return (
            <Modal
                isOpen={true}
                onClose={() => {
                    setShowListDevis(false)
                }}
            >
                <h2 className='modal__content-title'>Mes devis</h2>
                <CreateQuotationList {...props} />
            </Modal>
        )
    }
}

interface LigneProps {
    user: User | null | undefined
    quotation: apiDevisList.Datum
    setData: (value: { id: number }) => void
    data: { id: number }
    closeModal: () => void
    index: number

    setListquotation: (value: apiDevisList.inputDevisList) => void
}

const Ligne = (props: LigneProps) => {
    const { user, quotation, index, closeModal, setListquotation, setData } = props
    const [ShowLoading, setShowLoading] = useState(false)
    const [deleteLigne, setDeleteLigne] = useState(false)

    const navigate = useNavigate()
    const location = useLocation()

    const handleDelete = async () => {
        if (user) {
            setShowLoading(true)
            await user?.removeDevis(quotation.id)

            const newList = await Devis.getAllDevis(user?.id, user?.jwt)
            user.list_devis = newList

            setDeleteLigne(true)

            setTimeout(() => {
                if (props.data.id === quotation.id) {
                    console.log('delete current quotation')
                    setData({ id: 0 })
                }
                setListquotation(newList)
            }, 500)
        }
    }

    let style: React.CSSProperties = {}
    let styleBtnOpen: string

    if (deleteLigne) {
        style = {
            animationFillMode: 'forwards',
            animationName: 'fade-in',
            animationDuration: '0.5s',
        }
    } else {
        style = {
            transition: 'all 0.3s ease-in-out ',
        }
    }

    if (location.pathname.includes('devis') && props.data.id === quotation.id) {
        styleBtnOpen = 'table__btn-open'
    } else {
        styleBtnOpen = ''
    }

    return (
        <tr style={style} key={index + quotation.attributes.doc.doc_data.info.ref}>
            <td data-label={'Nom du devis'}>{quotation.attributes.doc.name}</td>
            <td data-label={'Date'} className='tableMobile'>
                {quotation.attributes.doc.doc_data.info.date}
            </td>
            <td data-label={'Référence'} className='tableMobile'>
                {quotation.attributes.doc.doc_data.info.ref}
            </td>
            <td className='table__btn'>
                <button
                    className={styleBtnOpen}
                    onClick={() => {
                        if (location.pathname !== '/devis') {
                            navigate('/devis')
                            if (props.data.id !== quotation.id) {
                                setData({ id: quotation.id })
                            }
                            closeModal()
                        } else {
                            if (props.data.id !== quotation.id) {
                                setData({ id: quotation.id })
                                closeModal()
                            }
                        }
                    }}
                >
                    Ouvrir
                </button>
                <button onClick={async () => handleDelete()}>
                    {ShowLoading && <Loading />}
                    Supprimer
                </button>
            </td>
        </tr>
    )
}

export default QuotationList
