/* eslint-disable prettier/prettier */
import { ChangeEvent, useState } from 'react'
import { Avatar, Button, InputForm, Modal } from '../components'
import { appInterface } from '../interface'
import User from '../util/user'

const Profil = (props: appInterface.props) => {
    document.title = 'Profil'

    if (!props.user) {
        document.location.href = '/'
        return <h1>Redirection en cour</h1>
    }

    const [curentUser, setcurentUser] = useState(getCurentUser(props.user))
    const [avatarLoading, setAvatarLoading] = useState(false)
    const [logoLoading, setlogoLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showProfile, setShowProfile] = useState(true)
    const [deleteCompteInputValue, setDeleteCompteInputValue] = useState({ value: '', error: '' })

    const handelAvatar = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return
        }

        setAvatarLoading(true)

        const picFile: FileList = event.target.files

        props.user
            ?.add_avatar(picFile[0])
            .then((result) => {
                if (!result) {
                    throw new Error('result avatar null')
                }
                props.showAlert('Votre avatar a bien été mie à jour', 3000, 'success')
                refrechPage(props.user, props.UpdateUser, setcurentUser, setAvatarLoading)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handeLogoCompany = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return
        }

        const picFile: FileList = event.target.files

        setlogoLoading(true)

        props.user
            ?.addLogoCompany(picFile[0], props.user.id, true)
            .then(() => {
                if (!props.user) {
                    throw new Error('user in props null or undefined')
                }
                props.showAlert("Votre logo d'entreprise a bien été mie à jour", 3000, 'success')
                refrechPage(props.user, props.UpdateUser, setcurentUser, setlogoLoading)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handelFormProfilUser = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        setAvatarLoading(true)
        props.user
            ?.setProfilUser({
                username: curentUser.username,
                email: curentUser.email,
                password: curentUser.mp1,
                password2: curentUser.mp2,
            })
            .then(() => {
                props.showAlert('Votre profil a bien êtes mis ajour', 3000, 'success')
                refrechPage(props.user, props.UpdateUser, setcurentUser, setAvatarLoading)
            })

            .catch((error: Error) => {
                const u = { ...curentUser }

                switch (error.message) {
                    case 'Email already taken':
                        u.emailError = 'Adresse e-mail déjà prise'
                        break
                    case 'the two passwords are not the same':
                        u.mp1Error = 'Les deux mot de passe ne sont pas identique'
                        u.mp2Error = 'Les deux mot de passe ne sont pas identique'
                        break
                    case 'two password missing required fields':
                        u.mp1Error = 'Les deux champs doivent être remplis '
                        u.mp2Error = 'Les deux champs doivent être remplis '
                        break
                    case 'password is not valid':
                        u.mp1Error =
                            'Le mot de passe doit avoir un caractère spécial des caractères majuscules et minuscules et faire 8 caractères minimum'

                        break
                    case 'Username already taken':
                        u.usernameError = "Nom d'utilisateur déjà pris"
                        break
                    default:
                        console.log(error)
                        break
                }

                setcurentUser(u)
                setAvatarLoading(false)
            })
    }

    const handelFormProvider = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        setlogoLoading(true)

        props.user
            ?.setDefaultProvider({
                address: curentUser.defaultProvider.address,
                city: curentUser.defaultProvider.city,
                zip_code: curentUser.defaultProvider.zip_code,
                siret: curentUser.defaultProvider.siret,
                phone: curentUser.defaultProvider.phone,
                email_pro: curentUser.defaultProvider.email_pro,
                mobile: curentUser.defaultProvider.mobile,
                tva: curentUser.defaultProvider.tva,
                fax: curentUser.defaultProvider.fax,
                company_name: curentUser.defaultProvider.company_name,
            })
            .then(() => {
                props.showAlert('Les infos de votre entreprise sont bien ajour', 3000, 'success')
                refrechPage(props.user, props.UpdateUser, setcurentUser, setlogoLoading)
            })
    }

    return (
        <>
            <div className='profil__hero'>
                <h1>{curentUser?.username}</h1>
            </div>
            <main className='profil padding-content'>
                <div className='profil__switch '>
                    <Button
                        color='secondary'
                        callBack={() => setShowProfile(true)}
                        enabeld={showProfile}
                        size='btn--small'
                    >
                        Profil personnel
                    </Button>
                    <Button
                        color='secondary'
                        callBack={() => setShowProfile(false)}
                        enabeld={!showProfile}
                        size='btn--small'
                    >
                        Profil entreprise
                    </Button>
                </div>
                {showProfile && (
                    <div className='profil__content'>
                        <Avatar
                            src={curentUser.avatar}
                            alt="photo représent l'avatar de l'utillisateur"
                            width={119}
                            height={119}
                            customClass='profil__content-img'
                            action={() => {
                                document.getElementById('avatar_input')?.click()
                            }}
                            isloading={avatarLoading}
                        />
                        <h2>Informations personnelles</h2>

                        <form onSubmit={(event) => handelFormProfilUser(event)}>
                            <input
                                style={{ display: 'none' }}
                                type='file'
                                name='avatar'
                                id='avatar_input'
                                onChange={(e) => handelAvatar(e)}
                            />
                            <InputProfil
                                label='Nom'
                                placeholder='Votre non'
                                value={curentUser?.username}
                                name='nom'
                                error={curentUser.usernameError}
                                type='text'
                                onchange={(event) =>
                                    setcurentUser({
                                        ...curentUser,
                                        username: event.target.value,
                                    })
                                }
                            />
                            <InputProfil
                                label='Email'
                                placeholder='Votre email'
                                value={curentUser.email}
                                name='email'
                                type='email'
                                error={curentUser.emailError}
                                onchange={(event) =>
                                    setcurentUser({
                                        ...curentUser,
                                        email: event.target.value,
                                    })
                                }
                            />
                            <InputProfil
                                label='Mot de passe'
                                placeholder='Votre nouveau mot de passe'
                                value={''}
                                name='password'
                                type='password'
                                error={curentUser.mp1Error}
                                onchange={(event) =>
                                    setcurentUser({
                                        ...curentUser,
                                        mp1: event.target.value,
                                    })
                                }
                            />
                            <InputProfil
                                label='Confirmation Mot de passe'
                                placeholder='Confirmation nouveau mot de passe'
                                value={''}
                                name='password'
                                type='password'
                                error={curentUser.mp2Error}
                                onchange={(event) =>
                                    setcurentUser({
                                        ...curentUser,
                                        mp2: event.target.value,
                                    })
                                }
                            />

                            <input type='submit' value='Sauvgarder les modifications' />
                        </form>
                        <span className='profil__btn-delete' onClick={() => setShowModal(true)}>
                            Supprimer mon compte
                        </span>
                        {showModal && (
                            <Modal
                                isOpen={true}
                                onClose={() => {
                                    setShowModal(false)
                                }}
                            >
                                <div className='profil__delete'>
                                    <p>
                                        Veuillez entrer votre mot de passe pour valider la
                                        <span>suppression</span>
                                    </p>
                                    <InputForm
                                        value={deleteCompteInputValue.value}
                                        placeholder='Votre mot de passe'
                                        label={null}
                                        type={'password'}
                                        callback={(value) =>
                                            setDeleteCompteInputValue({
                                                value: value.target.value,
                                                error: deleteCompteInputValue.error,
                                            })
                                        }
                                        error={deleteCompteInputValue.error}
                                    />

                                    <button
                                        onClick={() => {
                                            props.user
                                                ?.deleteUser(deleteCompteInputValue.value)
                                                .then(() => {
                                                    props.showAlert(
                                                        'Votre compte a été supprimé de nos bases de données',
                                                        3000,
                                                        'success',
                                                    )

                                                    props.UpdateUser(null)
                                                })
                                                .catch((error: Error) => {
                                                    switch (error.message) {
                                                        case 'password is not valid':
                                                            setDeleteCompteInputValue({
                                                                value: deleteCompteInputValue.value,
                                                                error: 'Le mot de passe doit avoir un caractère spécial des caractères majuscules et minuscules et faire 8 caractères minimum',
                                                            })
                                                            break
                                                        case 'Invalid identifier or password':
                                                            setDeleteCompteInputValue({
                                                                value: deleteCompteInputValue.value,
                                                                error: 'Mot de passe incorrect',
                                                            })
                                                            break
                                                        default:
                                                            break
                                                    }

                                                    console.log(error)
                                                })
                                        }}
                                    >
                                        Je confirme
                                    </button>
                                </div>
                            </Modal>
                        )}
                    </div>
                )}

                {!showProfile && (
                    <div className='profil__content'>
                        <Avatar
                            src={
                                curentUser.defaultProvider.logo
                                    ? curentUser.defaultProvider.logo.url
                                    : null
                            }
                            alt="photo représent l'avatar de l'utillisateur"
                            width={119}
                            height={119}
                            action={() => {
                                document.getElementById('logo_input')?.click()
                            }}
                            customClass='profil__content-img'
                            isloading={logoLoading}
                        />
                        <h2>Informations entreprises</h2>

                        <form onSubmit={(event) => handelFormProvider(event)}>
                            <input
                                style={{ display: 'none' }}
                                type='file'
                                name='logo'
                                id='logo_input'
                                onChange={(e) => handeLogoCompany(e)}
                            />

                            <InputProfil
                                label='Nom'
                                placeholder='Nom de votre entreprise'
                                value={curentUser.defaultProvider.company_name}
                                name='nom'
                                type='text'
                                onchange={(event) => {
                                    const u = { ...curentUser }
                                    u.defaultProvider.company_name = event.target.value
                                    setcurentUser(u)
                                }}
                            />
                            <InputProfil
                                label='E-mail pro'
                                placeholder='Email de votre entreprise'
                                value={curentUser.defaultProvider.email_pro}
                                name='email_pro'
                                type='email'
                                onchange={(event) => {
                                    const u = { ...curentUser }
                                    u.defaultProvider.email_pro = event.target.value
                                    setcurentUser(u)
                                }}
                            />
                            <InputProfil
                                label='Addresse'
                                placeholder='Adresse de votre entreprise'
                                value={curentUser.defaultProvider.address}
                                name='adresse'
                                type='text'
                                onchange={(event) => {
                                    const u = { ...curentUser }
                                    u.defaultProvider.address = event.target.value
                                    setcurentUser(u)
                                }}
                            />
                            <InputProfil
                                label='Ville'
                                placeholder='Ville'
                                value={curentUser.defaultProvider.city}
                                name='city'
                                type='text'
                                onchange={(event) => {
                                    const u = { ...curentUser }
                                    u.defaultProvider.city = event.target.value
                                    setcurentUser(u)
                                }}
                            />
                            <InputProfil
                                label='Code postal'
                                placeholder='Code postal'
                                value={curentUser.defaultProvider.zip_code}
                                name='zip_code'
                                type='text'
                                onchange={(event) => {
                                    const u = { ...curentUser }
                                    u.defaultProvider.zip_code = event.target.value
                                    setcurentUser(u)
                                }}
                            />

                            <InputProfil
                                label='N° siret'
                                placeholder='Votre numéro de siret'
                                value={curentUser.defaultProvider?.siret}
                                name='sirette'
                                type='text'
                                onchange={(event) => {
                                    const u = { ...curentUser }
                                    u.defaultProvider.siret = event.target.value
                                    setcurentUser(u)
                                }}
                            />
                            <InputProfil
                                label='Fax'
                                placeholder='Fax'
                                value={curentUser.defaultProvider?.fax}
                                name='fax'
                                type='text'
                                onchange={(event) => {
                                    const u = { ...curentUser }
                                    u.defaultProvider.fax = event.target.value
                                    setcurentUser(u)
                                }}
                            />
                            <InputProfil
                                label='Téléphone FIxe'
                                placeholder='Téléphone FIxe'
                                value={curentUser.defaultProvider?.phone}
                                name='phone'
                                type='text'
                                onchange={(event) => {
                                    const u = { ...curentUser }
                                    u.defaultProvider.phone = event.target.value
                                    setcurentUser(u)
                                }}
                            />
                            <InputProfil
                                label='Mobile'
                                placeholder='Mobile'
                                value={curentUser.defaultProvider.mobile}
                                name='mobile'
                                type='text'
                                onchange={(event) => {
                                    const u = { ...curentUser }
                                    u.defaultProvider.mobile = event.target.value
                                    setcurentUser(u)
                                }}
                            />
                            <InputProfil
                                label='Tva'
                                placeholder='La TVA applicable à votre entreprise'
                                value={
                                    curentUser.defaultProvider.tva
                                        ? curentUser.defaultProvider.tva.toString()
                                        : null
                                }
                                name='tva'
                                type='number'
                                onchange={(event) => {
                                    const u = { ...curentUser }
                                    u.defaultProvider.tva = event.target.value
                                    setcurentUser(u)
                                }}
                            />

                            <input type='submit' value='Sauvegarder les modifications' />
                        </form>
                    </div>
                )}
            </main>
        </>
    )
}
interface InputProps {
    label: string
    placeholder: string
    value: string | undefined | null
    name: string
    type: string
    error?: string
    onchange: (event) => void
}
const InputProfil = (props: InputProps) => {
    const [focus, setFocus] = useState(false)

    const classInputContenair = focus ? 'profil__inputFocus' : 'profil__input'
    if (props.value === undefined) {
        props.value = null
    }
    return (
        <div>
            <span className='profil__input-error'>{props.error}</span>
            <div className={classInputContenair}>
                <label>{props.label}</label>

                {props.value && (
                    <input
                        type={props.type}
                        name={props.name}
                        placeholder={props.placeholder}
                        value={props.value}
                        onChange={(e) => props.onchange(e)}
                        onSelect={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                    />
                )}
                {!props.value && (
                    <input
                        type={props.type}
                        name={props.name}
                        placeholder={props.placeholder}
                        onChange={(e) => props.onchange(e)}
                        onSelect={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                    />
                )}
            </div>
        </div>
    )
}

export default Profil

function getCurentUser(user: User | null | undefined) {
    if (!user) {
        throw new Error('user undefined or null')
    }

    return {
        username: user?.username,
        usernameError: '',
        email: user?.email,
        emailError: '',
        mp1: '',
        mp1Error: '',
        mp2: '',
        mp2Error: '',
        avatar: user.avatar?.data ? user.avatar?.data.attributes.pic.data.attributes.url : null,
        defaultProvider: !user.default_provider
            ? {
                  id: 0,
                  email_pro: '',
                  company_name: '',
                  address: '',
                  siret: '',
                  zip_code: '',
                  city: '',
                  phone: '',
                  fax: '',
                  mobile: '',
                  logo: {
                      url: '',
                  },
                  tva: 0,
              }
            : user.default_provider,
    }
}

function refrechPage(
    user: User | null | undefined,
    UpdateUser: (user: User | null) => void,
    setcurentUser: (value) => void,
    setAvatarLoading: (value: boolean) => void,
) {
    if (!user) {
        throw new Error('user in props null or undefined')
    }
    User.refrechUser(user.jwt, user.id).then((result) => {
        UpdateUser(result)
        setcurentUser(getCurentUser(result))
        setAvatarLoading(false)
    })
}
