import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { appInterface } from '../interface'
import { useNavigate } from 'react-router'
import User from '../util/user'

const ResetPassword = (props: appInterface.props) => {
    document.title = 'Réinitialiser le mot de passe'

    const { code } = useParams()
    const navigate = useNavigate()
    const [password, setPassword] = useState({
        password: '',
        passwordError: '',
        confirmPassword: '',
        confirmPasswordError: '',
    })

    useEffect(() => {
        if (!code) {
            props.showAlert('Code invalide', 3000, 'error')

            navigate('/')
        }
    }, [code])

    const handleInput = (value: { target: { name: string; value: string } }) => {
        if (value.target.name === 'password') {
            setPassword({ ...password, password: value.target.value })
        }
        if (value.target.name === 'confirmPassword') {
            setPassword({ ...password, confirmPassword: value.target.value })
        }
        /* setPassword({ ...password, confirmPasswordError: '', passwordError: '' }) */
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (password.password !== '' && password.confirmPassword !== '') {
            User.rezetPassword(code ? code : '', password.password, password.confirmPassword)
                .then(() => {
                    props.showAlert('Votre mot de passe a été modifié', 3000, 'success')
                    navigate('/')
                })
                .catch((error) => {
                    console.log(error)
                    const newpasswordError = { ...password }
                    switch (error.message) {
                        case 'the two passwords are not the same':
                            newpasswordError.passwordError =
                                'Les deux mots de passe ne sont pas identiques'
                            newpasswordError.confirmPassword =
                                'Les deux mots de passe ne sont pas identiques'

                            break
                        case 'two password missing required fields':
                            newpasswordError.passwordError = 'Les deux champs doivent être remplis '
                            newpasswordError.confirmPassword =
                                'Les deux champs doivent être remplis '

                            break
                        case 'password is not valid':
                            newpasswordError.passwordError =
                                'Le mot de passe doit avoir un caractère spécial des caractères majuscules et minuscules et faire 8 caractères minimum'

                            newpasswordError.confirmPassword = ''

                            break
                        default:
                            break
                    }

                    setPassword({ ...newpasswordError })

                    props.showAlert('Erreur lors de la modification du mot de passe', 3000, 'error')
                })
        } else {
            props.showAlert('Veuillez remplir tous les champs', 3000, 'error')
        }
    }

    return (
        <>
            <div className='profil__hero profil-center'>
                <h1>Réinitialiser votre mot de passe</h1>
            </div>
            <main>
                <form onSubmit={(e) => handleSubmit(e)} className='reset-form'>
                    <div className='reset-form__block'>
                        {password.passwordError && (
                            <p className='reset-form__error'>{password.passwordError}</p>
                        )}
                        <label htmlFor='pass'>Votre nouveaux mot de passe</label>

                        <input
                            id='pass'
                            type='password'
                            name='password'
                            placeholder='Password'
                            onChange={(e) => handleInput(e)}
                        />
                    </div>
                    <div className='reset-form__block'>
                        {password.confirmPasswordError && (
                            <p className='reset-form__error'>{password.confirmPasswordError}</p>
                        )}
                        <label htmlFor='confp'>confimée votre nouveaux mot de passe</label>
                        <input
                            id='confp'
                            type='password'
                            name='confirmPassword'
                            placeholder='Password Confirm'
                            onChange={(e) => handleInput(e)}
                        />
                    </div>
                    <input type='submit' value='Reset Password' />
                </form>
            </main>
        </>
    )
}

export default ResetPassword
