/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react'
import {
    Button,
    InfoCustomerAndProvider,
    PanelSaveDevis,
    Modal,
    Login,
    Register,
    Loading,
    TableDevis,
    SettingsDevis,
} from '../components'
import LogoDefault from '../assets/img/logo_utilisateur.jpg'
import { appInterface, module_custon } from '../interface'
import config from '../config/conf'
import Logic from '../util/logic'
import User from '../util/user'
import { motion } from 'framer-motion'

const Quotation = (props: appInterface.props) => {
    document.title = 'Devis'

    const [showSaveProfile, setShowSaveProfile] = useState(false)
    const [showLoginPanel, setShowLoginPanel] = useState(false)
    const [showRegisterPanel, setShowRegisterPanel] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [typePage, setTypePage] = useState('devis')
    const [curentDevis, setCurentdevis] = useState(Logic.newDevis(props.user))
    const [total, setTotal] = useState({
        discount: 0,
        totalHT: 0,
        totalTVA: 0,
        totalTTC: 0,
    })
    const [customTable, setCustomTable] = useState({
        colorHeader: '#e2daeb',
        colortextHeader: '#000000',
        colorLigne: '#ffffff',
        colortextLigne: '#000000',
        colorLigne2: '#F5F0FC',
        colortextLigne2: '#000000',
        devise: '€',
        discountPerLine: true,
        autoentrepreneur: false,
        tva: true,
    })

    const handleSave = () => {
        Logic.saveDevis(
            curentDevis,
            props.user,
            config,
            customTable,
            props.showAlert,
            setCurentdevis,
            setShowLoginPanel,
            setShowRegisterPanel,
            setShowModal,
            setShowSaveProfile,
            props.setData,
        )
    }

    const [logoShowLoading, setLogoShowLoading] = useState(false)

    useEffect(() => {
        if (props.data && props.data.id !== curentDevis.data.id) {
            if (props.data.id === 0) {
                setCurentdevis(Logic.newDevis(props.user))
                return
            }
            props.user?.getDevisById(props.data.id).then((_devi) => {
                if (props.user) {
                    const devi: module_custon.customDevi = {
                        data: {
                            id: props.data?.id,
                            id_user: props.user.id,
                            provider: _devi.provider,
                            doc: _devi.doc,
                        },
                    }

                    devi.data.provider.logo.url = _devi.provider.logo.data
                        ? _devi.provider.logo.data.attributes.url
                        : null
                    devi.data.provider.logo.id = _devi.provider.logo.data
                        ? _devi.provider.logo.data.id
                        : null
                    setCurentdevis(devi)
                    setCustomTable(_devi.doc.doc_data.customTable)
                }
            })
        }

        if (props.user) {
            /*  console.info('user update') */
        }
    }, [props.data, props.user])

    return (
        <main
            className='devis padding-content'
            style={{
                ['--custom-ligne2-color' as string]: customTable.colorLigne2,
                ['--custom-ligne2-text-color' as string]: customTable.colortextLigne2,

                ['--custom-ligne-color' as string]: customTable.colorLigne,
                ['--custom-ligne-text-color' as string]: customTable.colortextLigne,
            }}
        >
            <input
                type='file'
                style={{ display: 'none' }}
                id='dev-file-input'
                onChange={(e) => {
                    setLogoShowLoading(true)
                    if (e.target.files) {
                        Logic.uploadFile(e.target.files, props.user, config)
                            .then((res) => {
                                if (res) {
                                    const newDevis = { ...curentDevis }


                                    newDevis.data.provider.logo = {
                                        id: res.id,
                                        url: res.url,
                                    }

                                    setLogoShowLoading(false)
                                    setCurentdevis(newDevis)
                                }
                            })
                            .catch((error: Error) => {
                                let m = error.message

                                if (m === 'Please login to upload your logo') {
                                    m = 'Veuillez vous connecter pour télécharger votre logo'
                                } else {
                                    return
                                }
                                props.showAlert(m, 3000, 'error')
                            })
                    }
                }}
            />
            {showSaveProfile && (
                <Modal
                    isOpen={true}
                    onClose={() => {
                        setShowSaveProfile(false)
                    }}
                >
                    <PanelSaveDevis
                        onValidate={() => {
                            if (props.user) {
                                User.refrechUser(props.user?.jwt, props.user?.id)
                                    .then((user: User) => {
                                        props.UpdateUser(user)
                                        handleSave()
                                    })
                                    .catch((err: Error) => {
                                        throw new Error(err.message)
                                    })
                            }
                        }}
                        user={props.user}
                        devis={curentDevis}
                        showAlert={props.showAlert}
                        onCancel={() => {
                            'cancel'
                        }}
                    >
                        <h2>Définir les in fos personnels de votre entreprise</h2>
                        <p>
                            Vous navée pas encore défini les informations de votre entreprise voulez
                            vous les définir maintenant avec les informations de ce devis ? <br />{' '}
                            Vos informations seront utilisées pour remplire automatiquement certaine
                            partie de votre devis la prochaine fois.
                        </p>
                    </PanelSaveDevis>
                </Modal>
            )}
            {showModal && (
                <Modal isOpen={true} onClose={() => setShowModal(false)}>
                    {showLoginPanel && (
                        <Login
                            showAlert={props.showAlert}
                            UpdateUser={props.UpdateUser}
                            toogleRegisterAndLogin={() => {
                                setShowLoginPanel(false)
                                setShowRegisterPanel(true)
                            }}
                            callback={() => {
                                setShowModal(false)
                            }}
                        />
                    )}
                    {showRegisterPanel && (
                        <Register
                            showAlert={props.showAlert}
                            UpdateUser={props.UpdateUser}
                            toogleRegisterAndLogin={() => {
                                setShowLoginPanel(true)
                                setShowRegisterPanel(false)
                            }}
                            callback={() => {
                                setShowModal(false)
                            }}
                        />
                    )}
                </Modal>
            )}
            <div className='devis__btn-menu '>
                <Button color={'secondary'} callBack={() => handleSave()} size='btn--small'>
                    Sauvegarder
                </Button>

                <Button
                    color={'primary'}
                    callBack={() => {
                        setCurentdevis(Logic.newDevis(props.user))
                    }}
                >
                    Nouveau devis
                </Button>
            </div>

            <div className='devis__container'>
                <section className='devis__containt'>
                    <div className='devis__header'>
                        <span>
                            {logoShowLoading && <Loading />}
                            <motion.img
                                src={
                                    curentDevis.data.provider.logo.url
                                        ? config.api.urlBase + curentDevis.data.provider.logo?.url
                                        : LogoDefault
                                }
                                alt='logo prestataire'
                                loading='lazy'
                                whileHover={{
                                    scale: 1.1,
                                    transition: { duration: 0.3 },
                                }}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    document.getElementById('dev-file-input')?.click()
                                }}
                            />
                        </span>

                        <div className='devis__containt-ref'>
                            <p>
                                {typePage === 'facture' ? 'Facture N°' : 'Devis N°'} :{' '}
                                {curentDevis.data.doc.doc_data.info.number}
                            </p>
                            <p>Date : {curentDevis.data.doc.doc_data.info.date}</p>
                            {typePage === 'facture' && (
                                <p>Ref : {curentDevis.data.doc.doc_data.info.ref}</p>
                            )}
                        </div>
                    </div>
                    <div className='devis__infos'>
                        <InfoCustomerAndProvider
                            label='Prestataire'
                            name={curentDevis.data.provider.company_name}
                            address={curentDevis.data.provider.address}
                            phone={curentDevis.data.provider.phone}
                            mobile={curentDevis.data.provider.mobile}
                            email={curentDevis.data.provider.email_pro}
                            fax={curentDevis.data.provider.fax}
                            city={curentDevis.data.provider.city}
                            zipCode={curentDevis.data.provider.zip_code}
                            siret={curentDevis.data.provider.siret}
                            onChange={(e) => {
                                const c = { ...curentDevis }
                                c.data.provider.company_name = e.name
                                c.data.provider.address = e.address
                                c.data.provider.mobile = e.mobile
                                c.data.provider.phone = e.phone
                                c.data.provider.email_pro = e.email
                                c.data.provider.fax = e.fax
                                c.data.provider.city = e.city
                                c.data.provider.zip_code = e.zipCode
                                c.data.provider.siret = e.siret
                                setCurentdevis(c)
                            }}
                        />

                        <InfoCustomerAndProvider
                            label='Client'
                            name={curentDevis.data.doc.doc_data.client.name}
                            address={curentDevis.data.doc.doc_data.client.address}
                            phone={curentDevis.data.doc.doc_data.client.phone}
                            email={curentDevis.data.doc.doc_data.client.email}
                            fax={curentDevis.data.doc.doc_data.client.fax}
                            city={curentDevis.data.doc.doc_data.client.city}
                            zipCode={curentDevis.data.doc.doc_data.client.zipcode}
                            siret={curentDevis.data.doc.doc_data.client.siret}
                            onChange={(e) => {
                                const c = { ...curentDevis }
                                c.data.doc.doc_data.client.name = e.name
                                c.data.doc.doc_data.client.address = e.address
                                c.data.doc.doc_data.client.phone = e.phone
                                c.data.doc.doc_data.client.mobile = e.mobile
                                c.data.doc.doc_data.client.email = e.email
                                c.data.doc.doc_data.client.fax = e.fax
                                c.data.doc.doc_data.client.city = e.city
                                c.data.doc.doc_data.client.zipcode = e.zipCode
                                c.data.doc.doc_data.client.siret = e.siret
                                setCurentdevis(c)
                            }}
                        />
                    </div>

                    <TableDevis
                        curentDevis={curentDevis}
                        setCurentdevis={setCurentdevis}
                        customTable={customTable}
                        setTotal={setTotal}
                    />

                    <div>
                        <div className='devis__result'>
                            <div className='devis__result-tva'>
                                {customTable.tva &&
                                    `TVA ${curentDevis.data.doc.doc_data.provider.tva}%`}{' '}
                                {customTable.autoentrepreneur &&
                                    'Tva non applicable, art 293B du CGI '}
                                Pour toutes questions concernant ce devis, veuillez contacter :{' '}
                                <span>{curentDevis.data.provider.company_name}</span>
                                {curentDevis.data.provider.mobile ? (
                                    <span> au {curentDevis.data.provider.mobile}</span>
                                ) : curentDevis.data.provider.phone ? (
                                    <span> au {curentDevis.data.provider.phone}</span>
                                ) : null}
                                {curentDevis.data.provider.email_pro && (
                                    <span>
                                        {' ou par mail :' +
                                            ' ' +
                                            curentDevis.data.provider.email_pro}
                                    </span>
                                )}
                            </div>

                            <table>
                                <tbody>
                                    <tr>
                                        <td
                                            style={{
                                                backgroundColor: customTable.colorHeader,
                                                color: customTable.colortextHeader,
                                            }}
                                        >
                                            Remise totale
                                        </td>
                                        <td>{total.discount + ' ' + customTable.devise}</td>
                                    </tr>
                                    <tr>
                                        <td
                                            style={{
                                                backgroundColor: customTable.colorHeader,
                                                color: customTable.colortextHeader,
                                            }}
                                        >
                                            Total HT
                                        </td>
                                        <td> {total.totalHT + ' ' + customTable.devise}</td>
                                    </tr>
                                    {customTable.tva && (
                                        <tr>
                                            <td
                                                style={{
                                                    backgroundColor: customTable.colorHeader,
                                                    color: customTable.colortextHeader,
                                                }}
                                            >
                                                Total TVA
                                            </td>
                                            <td>
                                                {total.totalTVA +
                                                    ' ' +
                                                    customTable.devise +
                                                    ' / ' +
                                                    curentDevis.data.doc.doc_data.provider.tva}{' '}
                                                %
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td
                                            style={{
                                                backgroundColor: customTable.colorHeader,
                                                color: customTable.colortextHeader,
                                            }}
                                        >
                                            {' '}
                                            Total TTC
                                        </td>
                                        <td>{total.totalTTC + ' ' + customTable.devise}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='facture-notif'>
                            <div className='facture-notif__champ'>
                                {typePage === 'facture' && <p> fait à : </p>}
                                {typePage === 'facture' && <p> le : ___/___/___</p>}
                            </div>
                            <div>{typePage === 'facture' && <p>Signature : </p>}</div>
                        </div>

                        {typePage === 'devis' && (
                            <p className='devis__validate'>
                                Devis valable jusqu&apos; au:{' '}
                                <span
                                    className='helement-edit '
                                    contentEditable={true}
                                    suppressContentEditableWarning={true}
                                    onClick={(e: any) => {

                                        if (e.target.innerText === 'Entrer la date') {
                                            e.target.innerText = ''
                                        }
                                    }}

                                    onBlur={(e: any) => {
                                        if (e.target.innerText === '') {
                                            e.target.innerText = 'Entrer la date'
                                        }
                                    }
                                    }

                                >
                                    Entrer la date
                                </span>
                            </p>
                        )}
                    </div>
                </section>

                <SettingsDevis
                    curentDevis={curentDevis}
                    setCurentdevis={setCurentdevis}
                    customTable={customTable}
                    setCustomTable={setCustomTable}
                    setTotal={setTotal}
                    setTypePage={setTypePage}
                    typePage={typePage}
                />
            </div>
        </main>
    )
}

export default Quotation
