/* Picture */
import logo from '../assets/img/logo/Logo.svg'
/* ICons */
/* Navigation */
import { useLocation, useNavigate } from 'react-router'
/* React */
import React, { useState, useEffect } from 'react'
/* Components */
import {
    Login,
    Register,
    Logout,
    Avatar,
    HeaderMobile,
    Modal,
    QuotationList,
    ForgotPassword,
} from './'
/* user  */
import { appInterface } from '../interface'
import { AnimatePresence } from 'framer-motion'
import { Link } from 'react-router-dom'
import User from '../util/user'

const Header: React.FC<appInterface.props> = (props: appInterface.props) => {
    const navigate = useNavigate() // React router navigate
    const { user } = props

    const [isLogin, setIsLogin] = useState(false)
    const [isRegister, setIsRegister] = useState(false)
    const [showListDevis, setShowListDevis] = useState(false)
    const [showForgotPassword, setShowForgotPassword] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const toogleRegisterAndLogin = () => {
        setIsLogin(!isLogin)
        setIsRegister(!isRegister)
    }

    const handleListDevis = () => {
        setShowModal(true)
        setShowListDevis(true)
    }

    const handleLogin = () => {
        setShowModal(true)
        setIsLogin(true)
        setIsRegister(false)
        setShowForgotPassword(false)
    }
    const handleRegister = () => {
        setShowModal(true)
        setIsLogin(false)
        setIsRegister(true)
        setShowForgotPassword(false)
    }
    const handleClose = () => {
        setShowModal(false)
        setIsLogin(false)
        setIsRegister(false)
        setShowForgotPassword(false)
        setShowListDevis(false)
    }
    const handleUpdateUser = (value: User | null) => {
        if (value) {
            props.UpdateUser(value)
            setIsLogin(false)
            setIsRegister(false)
            setShowModal(false)
            setShowForgotPassword(false)
        }
    }

    useEffect(() => {
        if (user) {
            setIsLogin(false)
            setIsRegister(false)
        }
    }, [user])
    const location = useLocation()

    return (
        <>
            <AnimatePresence>
                {showModal && (
                    <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
                        {showForgotPassword && (
                            <ForgotPassword
                                app={props}
                                onClose={() => {
                                    handleClose()
                                }}
                            />
                        )}
                        {showListDevis && (
                            <QuotationList
                                user={user}
                                _listquotation={user?.list_devis}
                                setShowListDevis={() => {
                                    setShowListDevis(false)
                                    handleClose()
                                }}
                                closeModal={() => {
                                    setShowListDevis(false)
                                    handleClose()
                                }}
                                data={props.data ? props.data : { id: 0 }}
                                setData={props.setData ? props.setData : () => void 0}
                            />
                        )}
                        {isLogin && (
                            <Login
                                showAlert={props.showAlert}
                                UpdateUser={handleUpdateUser}
                                toogleRegisterAndLogin={toogleRegisterAndLogin}
                                showForgotPassword={() => {
                                    setIsLogin(false)
                                    setShowForgotPassword(true)
                                }}
                            />
                        )}
                        {isRegister && (
                            <Register
                                showAlert={props.showAlert}
                                UpdateUser={handleUpdateUser}
                                toogleRegisterAndLogin={toogleRegisterAndLogin}
                            />
                        )}
                    </Modal>
                )}
            </AnimatePresence>

            <HeaderMobile
                user={user}
                setShowListDevis={setShowListDevis}
                UpdateUser={props.UpdateUser}
                showAlert={props.showAlert}
                handleLogin={handleLogin}
                handleRegister={handleRegister}
            />

            <header className='headerPc '>
                <div className='headerPc__content'>
                    <img
                        className='headerPc-logo'
                        src={logo}
                        alt='logo'
                        width={40}
                        height={48}
                        onClick={() => navigate('/')}
                    />

                    <nav className='headerPc__nav'>
                        <ul className='headerPc__nav-block'>
                            <li className={location.pathname === '/' ? 'headerPc__nav-active' : ''}>
                                <Link to='/'>Accueil</Link>
                            </li>
                            {user && (
                                <li>
                                    <span className='headerPc__nav-indicator'>
                                        {user.list_devis?.data.length}
                                    </span>
                                    <button onClick={() => handleListDevis()}>Mes devis</button>
                                </li>
                            )}
                            <li>
                                <Link className='btn-primary' to='/devis'>
                                    Générer un devis
                                </Link>
                            </li>
                            <li
                                className={
                                    location.pathname === '/faq' ? 'headerPc__nav-active' : ''
                                }
                            >
                                <Link to='/faq'>Faq</Link>
                            </li>
                            <li
                                className={
                                    location.pathname === '/contact' ? 'headerPc__nav-active' : ''
                                }
                            >
                                <Link to='/contact'>Contact</Link>
                            </li>
                        </ul>
                    </nav>

                    <div className='headerPc__btn'>
                        {!user && <button onClick={() => handleRegister()}>Crée un compte</button>}

                        {!user && <button onClick={() => handleLogin()}>Se connecter</button>}

                        {user && (
                            <Avatar
                                src={
                                    user.avatar?.data
                                        ? user.avatar.data.attributes.pic.data.attributes.url
                                        : null
                                }
                                alt={`Utillisateur ${user.username}`}
                                width={50}
                                height={50}
                                action={() => navigate('/profil')}
                            />
                        )}

                        {user && <Logout {...props} />}
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header
