import User from '../util/user'
import { useState } from 'react'
import { InputForm, Loading } from '../components'

interface loginProps {
    showAlert: (message: string, timer: number, numbertype: string) => void
    UpdateUser: (value: User | null) => void
    toogleRegisterAndLogin: () => void
    callback?: () => void
    showForgotPassword?: () => void
}

const Login = (props: loginProps) => {
    const [showLoading, setShowLoading] = useState(false)
    const { showAlert, UpdateUser, toogleRegisterAndLogin } = props

    const [userConnect, setUserConnect] = useState<{
        name: string
        nameError: string
        password: string
        passwordError: string
    }>({
        name: '',
        nameError: '',
        password: '',
        passwordError: '',
    })

    const handleInput = (value: { target: { name: string; value: string } }) => {
        if (value.target.name === 'username') {
            setUserConnect({ ...userConnect, name: value.target.value })
        }
        if (value.target.name === 'password') {
            setUserConnect({ ...userConnect, password: value.target.value })
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSubmit = (event: any) => {
        event.preventDefault()
        setShowLoading(true)
        const user_name = userConnect.name
        /* get is password format is correct */
        const password = userConnect.password

        User.login({
            identifier: user_name,
            password: password,
        })
            .then((userData) => {
                UpdateUser(userData)
                showAlert('Vous êtes connecté', 3000, 'success')
                setShowLoading(false)
                props.callback && props.callback()
            })
            .catch((error: Error) => {
                const u = { ...userConnect }
                console.log(error)
                switch (error.message) {
                    case 'password is not valid':
                        u.passwordError =
                            'Le mot de passe doit avoir un caractère spécial des caractères majuscules et minuscules et faire 8 caractères minimum'
                        u.nameError = ''
                        break
                    case 'Invalid identifier or password':
                        u.nameError = 'Identifiant ou mot de passe invalide'
                        u.passwordError = 'Identifiant ou mot de passe invalide'
                        break
                    case 'username is not valid':
                        u.nameError =
                            "Le nom d'utilisateur doit contenir au minimum trois caractères"
                        u.passwordError = ''
                        break
                    default:
                        console.log(error)
                        break
                }

                setUserConnect(u)
                setShowLoading(false)
            })
    }

    return (
        <>
            <div className='login'>
                <div className='showLoading'>{showLoading && <Loading />}</div>
                <h1>Se connecter</h1>

                <div className='login__content'>
                    <form onSubmit={(event) => handleSubmit(event)}>
                        <InputForm
                            value={userConnect.name}
                            placeholder='Entrer votre nom'
                            name='username'
                            label='Nom'
                            type='text'
                            error={userConnect.nameError}
                            callback={(value: { target: { name: string; value: string } }) => {
                                handleInput(value)
                            }}
                        />
                        <InputForm
                            value={userConnect.password}
                            placeholder='Entrer votre mot de passe'
                            name='password'
                            label='Mot de passe'
                            type='password'
                            error={userConnect.passwordError}
                            callback={(value: { target: { name: string; value: string } }) => {
                                handleInput(value)
                            }}
                        />
                        <InputForm
                            value='Connexion'
                            placeholder='Connexion'
                            name='submit'
                            type='submit'
                            label={null}
                            callback={(value: { target: { name: string; value: string } }) => {
                                handleInput(value)
                            }}
                        />
                    </form>
                </div>
                <button
                    onClick={() => {
                        toogleRegisterAndLogin()
                    }}
                >
                    Vous n’avez pas de compte ? Crée en un ici
                </button>

                <button
                    onClick={() => {
                        props.showForgotPassword ? props.showForgotPassword() : null
                    }}
                >
                    Mot de passe oublié ?
                </button>
            </div>
        </>
    )
}

export default Login
