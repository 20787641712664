/* eslint-disable prettier/prettier */
import config from '../config/conf'
import Api from './api'
import { apiDevisList, apiHttp, apiUser, module_custon } from '../interface/apiInterface'

import User from './user'

class Devis {
    list_devis: apiDevisList.inputDevisList | null = null
    jwt: string
    id: number
    constructor(data: apiUser.inputUser) {
        this.id = data.user.id
        this.jwt = data.jwt
    }

    /**
     * creates an empty quote is returns it
     * @returns customDevi
     */
    static createDevi(): module_custon.customDevi {
        const new_devi: module_custon.customDevi = {
            data: {
                id: 0,
                id_user: 0,
                provider: {
                    email_pro: '',
                    company_name: '',
                    address: '',
                    siret: '',
                    zip_code: '',
                    city: '',
                    mobile: '',
                    fax: '',
                    phone: '',
                    tva: 0,
                    logo: 0,
                },
                doc: {
                    id: 0,
                    tag: 'quotation',
                    name: '',
                    doc_data: {
                        info: {
                            date: '',
                            number: 0,
                            ref: '',
                        },
                        client: {
                            name: '',
                            email: '',
                            phone: '',
                            mobile: '',
                            address: '',
                            city: '',
                            zipcode: '',
                            siret: '',
                            fax: '',
                        },
                        provider: {
                            name: '',
                            address: '',
                            city: '',
                            phone: '',
                            siret: '',
                            zipcode: '',
                            email: '',
                            tva: 0,
                            fax: '',
                        },
                        customTable: {},
                        lignes: [],
                    },
                },
            },
        }

        return new_devi
    }
    /**
     * return the list of quotes
     * @returns module_devislist.devis | null
     */
    static async getAllDevis(
        _userId: number,
        userJwt: string,
    ): Promise<apiDevisList.inputDevisList> {
        const user_list_devis: apiDevisList.inputDevisList = await Api.get(
            `${config.api.url}`,
            ` ${config.api.devis + `?filters[id_user]=${_userId}`}`,
            userJwt,
        )
        if (user_list_devis.error) {
            if (user_list_devis.error.message !== 'Not Found') {
                throw new Error(user_list_devis.error.message)
            }
        }

        return user_list_devis
    }

    /**
     * Retrieve in the API the quote that corresponds to this id
     * @param _id_devis : number
     * @returns  Promise<module_devis.Devi| null>
     */
    async getDevisById(_id_devis: number) {
        /* get in api */
        const devi = await Api.get(config.api.url, config.api.devis + _id_devis, this.jwt)

        if (devi.error?.status === 404) {
            throw new Error('not quotation in data base')
        }
        if (devi.error?.message) {
            throw new Error(devi.error.message)
        }

        if (parseInt(devi.data.attributes.id_user) !== this.id) {
            throw new Error('id_user quotation no equal curent user ')
        }

        return devi.data.attributes
    }

    async updateDevis(_custom_devi: module_custon.customDevi | null = null): Promise<boolean> {
        if (_custom_devi === null) {
            throw new Error('custom devi is null')
        }

        const devis: module_custon.customDevi = {
            data: {
                id_user: _custom_devi.data.id_user,
                provider: {
                    email_pro: _custom_devi.data.provider.email_pro,
                    company_name: _custom_devi.data.provider.company_name,
                    address: _custom_devi.data.provider.address,
                    siret: _custom_devi.data.provider.siret,
                    zip_code: _custom_devi.data.provider.zip_code,
                    city: _custom_devi.data.provider.city,
                    mobile: _custom_devi.data.provider.mobile,
                    fax: _custom_devi.data.provider.fax,
                    phone: _custom_devi.data.provider.phone,
                    tva: _custom_devi.data.provider.tva,
                    logo: _custom_devi.data.provider.logo.id,
                },
                doc: _custom_devi.data.doc,
            },
        }

        const res = await Api.put(
            config.api.url,
            config.api.devis + _custom_devi.data.id,
            JSON.stringify(devis),
            this.jwt,
        )

        if (res.error) {
            throw new Error(res.error.message)
        }

        return true
    }
    static getNextNumberDevis(user: User): number {
        return user.list_devis ? user.list_devis.data.length + 1 : 1
    }
    /**
     * add a quote in the api
     * @param _custom_devi : module_custon.customDevi | null
     * @returns Promise<boolean>
     */
    async addDevis(
        _custom_devi: module_custon.customDevi | null = null,
    ): Promise<module_custon.customDevi> {
        if (_custom_devi === null) {
            throw new Error('custom devi is null')
        }

        const res = await Api.post(
            config.api.url,
            config.api.devis,
            JSON.stringify(_custom_devi),
            this.jwt,
        )

        if (res.error) {
            throw new Error(res.error.message)
        }

        const newId = res.data.id
        const newDevi = await this.getDevisById(newId)

        const devis: module_custon.customDevi = {
            data: {
                id_user: newDevi.id_user,
                id: newDevi.id,
                provider: {
                    email_pro: newDevi.provider.email_pro,
                    company_name: newDevi.provider.company_name,
                    address: newDevi.provider.address,
                    siret: newDevi.provider.siret,
                    zip_code: newDevi.provider.zip_code,
                    city: newDevi.provider.city,
                    mobile: newDevi.provider.mobile,
                    fax: newDevi.provider.fax,
                    phone: newDevi.provider.phone,
                    tva: newDevi.provider.tva,
                    logo: {
                        url: newDevi.provider.logo.data
                            ? newDevi.provider.logo.data.attributes.url
                            : null,
                        id: newDevi.provider.logo.data ? newDevi.provider.logo.data.id : null,
                    },
                },
                doc: newDevi.doc,
            },
        }

        return devis
    }

    /**
     * delete a quote from the api
     * @param _id_devi : number
     * @returns Promise<boolean>
     */
    async removeDevis(_id_devi: number): Promise<boolean> {
        if (_id_devi === null || _id_devi === undefined || _id_devi === 0) {
            throw new Error('id devi is null or undefined or 0')
        }

        /* Delete devi */
        const res: apiHttp.Http_error = await Api.delete(
            `${config.api.url}`,
            ` ${config.api.devis + _id_devi}`,
            this.jwt,
        )

        if (res.error) {
            throw new Error(res.error.message)
        }

        return true
    }
}

export default Devis
