/* eslint-disable @typescript-eslint/no-explicit-any */
import User from '../util/user'
import { useState } from 'react'
import { InputForm, Loading } from '../components'

interface loginProps {
    showAlert: (message: string, timer: number, type: string) => void
    UpdateUser: (value: User | null) => void
    toogleRegisterAndLogin: () => void
    callback?: () => void
}
const Register = (props: loginProps) => {
    const { showAlert, UpdateUser, toogleRegisterAndLogin } = props
    const [showLoading, setShowLoading] = useState(false)

    const [newUser, setNewUser] = useState<{
        name: string
        nameError: string
        password: string
        passwordError: string
        password2: string
        password2Error: string
        email: string
        emailError: string
        legal: boolean
    }>({
        name: '',
        nameError: '',
        password: '',
        passwordError: '',
        password2: '',
        password2Error: '',
        email: '',
        emailError: '',
        legal: false,
    })

    const handleInput = (value: { target: { name: string; value: any } }) => {
        if (value.target.name === 'username') {
            setNewUser({ ...newUser, name: value.target.value })
        }
        if (value.target.name === 'password') {
            setNewUser({ ...newUser, password: value.target.value })
        }
        if (value.target.name === 'password2') {
            setNewUser({ ...newUser, password2: value.target.value })
        }
        if (value.target.name === 'email') {
            setNewUser({ ...newUser, email: value.target.value })
        }

        if (value.target.name === 'legal') {
            setNewUser({ ...newUser, legal: !newUser.legal })
        }
    }

    const handleSubmit = (event: any) => {
        event.preventDefault()
        setShowLoading(true)
        const name = newUser.name
        const password = newUser.password
        const password2 = newUser.password2
        const email = newUser.email

        if (name === '' || password === '' || password2 === '' || email === '') {
            setNewUser({
                ...newUser,
                nameError: 'Tous les champs doivent être remplis',
            })
            setShowLoading(false)

            return
        }

        if (!newUser.legal) {
            setNewUser({
                ...newUser,
                nameError: "Vous devez accepter les conditions d'utilisation ",
            })
            setShowLoading(false)

            return
        }

        User.register({
            username: name,
            email: email,
            password: password,
            password_confirm: password2,
        })
            .then((userData) => {
                if (userData !== null) {
                    UpdateUser(userData)
                    setShowLoading(false)
                    showAlert(
                        "Votre compte d'utilisateur a bien étés crée vous aller être connecter automatiquement.",
                        3000,
                        'success',
                    )
                    props.callback && props.callback()
                } else {
                    const u = { ...newUser }
                    u.nameError =
                        "Le serveur d'authentification ne répond pas, contacter l'administrateur via la page de contact pour lui faire part du problème survenu."
                    setShowLoading(false)
                }
            })
            .catch((error: Error) => {
                const u = { ...newUser }
                switch (error.message) {
                    case 'Email already taken':
                        u.emailError = 'Adresse e-mail déjà prise'

                        u.passwordError = ''
                        u.password2Error = ''
                        u.nameError = ''
                        break
                    case 'the two passwords are not the same':
                        u.passwordError = 'Les deux mots de passe ne sont pas identiques'
                        u.password2Error = 'Les deux mots de passe ne sont pas identiques'

                        u.emailError = ''
                        u.nameError = ''
                        break
                    case 'two password missing required fields':
                        u.passwordError = 'Les deux champs doivent être remplis '
                        u.password2Error = 'Les deux champs doivent être remplis '

                        u.emailError = ''
                        u.nameError = ''
                        break
                    case 'password is not valid':
                        u.passwordError =
                            'Le mot de passe doit avoir un caractère spécial des caractères majuscules et minuscules et faire 8 caractères minimum'

                        u.nameError = ''
                        u.password2Error = ''
                        u.emailError = ''
                        break
                    case 'Username already taken':
                        u.nameError = 'Le nom d’utilisateur est déjà utilisé '

                        u.passwordError = ''
                        u.password2Error = ''
                        u.emailError = ''
                        break
                    case 'username is not valid':
                        u.nameError =
                            "Le nom d'utilisateur doit contenir au minimum trois caractères"

                        u.passwordError = ''
                        u.password2Error = ''
                        u.emailError = ''
                        break
                    case 'email is not valid':
                        u.emailError = "L'adresse e-mail nés pas valide"
                        u.passwordError = ''
                        u.password2Error = ''
                        u.nameError = ''
                        break
                    case 'Please provide a valid email address':
                        u.emailError = "L'adresse e-mail nés pas valide"
                        u.passwordError = ''
                        u.password2Error = ''
                        u.nameError = ''
                        break
                    default:
                        console.log(error)
                        break
                }
                setNewUser(u)
                setShowLoading(false)
            })
    }

    return (
        <>
            <div className='register'>
                <div>{showLoading && <Loading />}</div>

                <h1>Register</h1>
                <div className='register__content'>
                    <form onSubmit={(event) => handleSubmit(event)}>
                        <InputForm
                            value={newUser.name}
                            placeholder='Entrer votre nom'
                            name='username'
                            label='Nom'
                            type='text'
                            error={newUser.nameError}
                            callback={(value: { target: { name: string; value: string } }) => {
                                handleInput(value)
                            }}
                        />
                        <InputForm
                            value={newUser.email}
                            placeholder='Entrer votre email'
                            name='email'
                            label='email'
                            type='email'
                            error={newUser.emailError}
                            callback={(value: { target: { name: string; value: string } }) => {
                                handleInput(value)
                            }}
                        />

                        <InputForm
                            value={newUser.password}
                            placeholder='Entrer votre mot de passe'
                            name='password'
                            label='Mot de passe'
                            type='password'
                            error={newUser.passwordError}
                            callback={(value: { target: { name: string; value: string } }) => {
                                handleInput(value)
                            }}
                        />

                        <InputForm
                            value={newUser.password2}
                            placeholder='Confirmer votre mot de passe'
                            name='password2'
                            label='Confirmation du mot de passe'
                            type='password'
                            error={newUser.password2Error}
                            callback={(value: { target: { name: string; value: string } }) => {
                                handleInput(value)
                            }}
                        />
                        <InputForm
                            value='Inscription'
                            placeholder='Inscription'
                            name='submit'
                            type='submit'
                            label={null}
                            callback={(value: { target: { name: string; value: string } }) => {
                                handleInput(value)
                            }}
                        />
                        <InputForm
                            value={newUser.legal}
                            placeholder=''
                            name='legal'
                            type='checkbox'
                            label="Accepter les conditions d'utilisation"
                            callback={(value: {
                                target: { name: string; value: string | boolean }
                            }) => {
                                handleInput(value)
                            }}
                        />
                    </form>
                </div>
                <button
                    onClick={() => {
                        toogleRegisterAndLogin()
                    }}
                >
                    Vous avez déjà un compte ? Connecter vous ici.
                </button>
            </div>
        </>
    )
}

export default Register
