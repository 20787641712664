import React from 'react'
import { appInterface } from '../interface'
import { useNavigate } from 'react-router'

const Logout = (props: appInterface.props) => {
    const { UpdateUser, showAlert, setData } = props
    const navigate = useNavigate()
    const handleLogout = () => {
        navigate('/')
        showAlert('Vous avez été déconnecté', 3000, 'success')
        if (setData) setData({ id: 0 })

        UpdateUser(null)
    }

    return (
        <div>
            <button onClick={() => handleLogout()}>Déconnexion</button>
        </div>
    )
}

export default Logout
