/* eslint-disable @typescript-eslint/no-explicit-any */
class Api {
    /**
     * GET API METHODE
     * @param url :string
     * @param params:?string
     * @param jwt:?string
     * @returns Promise<any>
     */

    public static async get(url: string, params?: string, jwt?: string): Promise<any> {
        const headers = {} as any

        headers['Content-Type'] = 'application/json'

        if (jwt) {
            headers['Authorization'] = `Bearer ${jwt}`
        }

        const options = {
            method: 'GET',
            headers: headers,
        }

        url = `${url}${params}`

        url = url.replace(' ', '')

        return await fetch(url, options)
            .then((response) => response.json())
            .catch((error) => {
                console.error(error)
                return null
            })
    }

    /**
     * POST API METHODE
     * @param url string
     * @param params  ?string
     * @param data  ? JSON|FormData
     * @param jwt ?string
     * @returns isJson boolean = true
     * @returns   Promise<any>
     */
    public static async post(
        url: string,
        params?: string,
        data?: any,
        jwt?: string,
        isJson = true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<any> {
        url = `${url}${params}`

        url = url.replace(' ', '')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const headers = {} as any
        if (isJson) {
            headers['Content-Type'] = 'application/json'
        }
        if (jwt) {
            headers['Authorization'] = `Bearer ${jwt}`
        }

        const options = {
            method: 'POST',
            headers: headers,
            body: data,
        }

        return await fetch(url, options)
            .then((response) => response.json())

            .catch((error) => {
                console.log(error)
                return null
            })
    }

    /**
     * PUT API METHODE
     * @param url string
     * @param params  ?string
     * @param data  ? JSON|FormData
     * @param jwt ?string
     * @returns  Promise<any>
     */
    public static async put(
        url: string,
        params?: any,
        data?: any,
        jwt?: string,
        isJson = true,
    ): Promise<any> {
        url = `${url}${params}`

        url = url.replace(' ', '')

        const headers = {} as any
        if (isJson) {
            headers['Content-Type'] = 'application/json'
        }
        if (jwt) {
            headers['Authorization'] = `Bearer ${jwt}`
        }
        const options = {
            method: 'PUT',
            headers: headers,
            body: data,
        }

        return await fetch(url, options)
            .then((response) => response.json())

            .catch((error) => {
                console.log(error)
                return null
            })
    }

    /**
     *  DELETE API METHODE
     * @param url string
     * @param params    ?string
     * @param data  ? JSON|FormData
     * @returns   Promise<any>
     */
    public static async delete(url: string, params?: any, jwt?: string): Promise<any> {
        url = `${url}${params}`

        url = url.replace(' ', '')

        const headers = {} as any

        if (jwt) {
            headers['Authorization'] = `Bearer ${jwt}`
        }

        const options = {
            method: 'DELETE',
            headers: headers,
        }

        return await fetch(url, options)
            .then((response) => response.json())

            .catch((error) => {
                console.log(error)
                return null
            })
    }
}

export default Api
