import logo from '../assets/img/logo/Logo.svg'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

const Footer = () => {
    const navigate = useNavigate() // React router navigate

    return (
        <>
            <footer className='footer padding-content'>
                <img src={logo} alt='logo' width={77} height={73} />
                <nav className='footer__nav'>
                    <ul className='footer__nav-block'>
                        <li>
                            <Link to='/'>Accueil</Link>
                        </li>
                        {/* <li>
                            <button>Voir devis</button>
                        </li> */}
                        <li>
                            <Link to='/devis'>Générer un devis</Link>
                        </li>
                        <li>
                            <Link to='/faq'>Faq</Link>
                        </li>
                        <li>
                            <Link to='/contact'>Contact</Link>
                        </li>
                    </ul>
                </nav>
                <div className='footer__copy'>
                    <p className='footer__copy-name'>© Copyright Gauvin Jonathan & Equerre Teddy</p>
                    <p onClick={() => navigate('/legal', { replace: false })}>Mention légale</p>
                </div>
            </footer>
        </>
    )
}
export default Footer
